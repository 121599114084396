import { EventStatus } from 'config/types';
import { appApi } from './app-api-slice';

export const commonApi = appApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getEventStatus: builder.query<EventStatus[], void>({
        query: () => ({
          url: '/event_status',
          credential: 'include',
          method: 'GET',
        }),
      }),
    };
  },
});

export const { useGetEventStatusQuery } = commonApi;
