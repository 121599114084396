import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  approveModalSelector,
  contractSelector,
  setApproveModal,
  setIsDone,
  setResultModal,
} from 'slices/app-slice';
import { Space, Card, Typography, Modal, Button, Input } from 'antd';
import { useCreateContractApproveMutation } from 'slices/contract-approve-api-slice';
import { useSendEmailsApproveByShareLinkMutation } from 'slices/contract-email-api-slice';
import { ContractAction } from 'config/enums';

export const ApproveModal = () => {
  const dispatch = useDispatch();
  const contract = useSelector(contractSelector);
  const approveModal = useSelector(approveModalSelector);
  const { Title } = Typography;
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [spinLoad, setSpinLoad] = useState(false);

  const [saveApprove] = useCreateContractApproveMutation();
  const [sendEmail] = useSendEmailsApproveByShareLinkMutation();

  useEffect(() => {
    if (contract) {
      if (contract.fullname) {
        setFullName(contract.fullname);
      }
      if (contract.email) {
        setEmail(contract.email);
      }
    }
  }, [contract]);
  const handleOk = async () => {
    setSpinLoad(true);
    await saveApprove({
      shareLink: contract.shareLink ? contract.shareLink : undefined,
      controlLink: contract.controlLink ? contract.controlLink : undefined,
      fullName: fullName,
      email: email,
    })
      .unwrap()
      .then(() => {
        dispatch(setIsDone(true));
        setSpinLoad(false);
        sendEmail({ shareLink: contract.shareLink });
        handleCancel();
        dispatch(
          setResultModal({ open: true, action: ContractAction.APPROVE })
        );
      });
  };
  const handleCancel = () => {
    if (!contract.fullname && !contract.email) {
      setEmail('');
      setFullName('');
    }
    dispatch(setApproveModal(false));
  };
  const handleChange = (e: any) => {
    switch (e.target.id) {
      case 'FullName':
        setFullName(e.target.value);
        break;

      case 'Email':
        setEmail(e.target.value);
        break;
    }
  };
  return (
    <Modal
      title="Approve this document"
      open={approveModal}
      centered
      onOk={handleOk}
      onCancel={handleCancel}
      footer={
        <>
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            disabled={!fullName || !email ? true : false}
            onClick={handleOk}
            loading={spinLoad}
          >
            Approve
          </Button>
        </>
      }
    >
      <Space
        direction="vertical"
        size="large"
        style={{ display: 'flex', margin: '32px 0 0 0' }}
      >
        <Card bordered={true}>
          <Space direction="vertical" size={16} style={{ display: 'flex' }}>
            <Space direction="vertical" size={2}>
              <Title level={5} style={{ margin: '0 0 0 0' }}>
                Leave your name and email
              </Title>
            </Space>
            <Input
              id="FullName"
              placeholder="Enter your full name"
              value={fullName}
              onChange={handleChange}
            />
            <Input
              id="Email"
              placeholder="Enter your email"
              value={email}
              onChange={handleChange}
            />
          </Space>
        </Card>
      </Space>
    </Modal>
  );
};
