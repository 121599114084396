import { Statistics } from 'config/types';
import { appApi } from './app-api-slice';

export const statisticsApi = appApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getStatistics: builder.query<Statistics, void>({
        query: () => ({
          url: `/statistics`,
          credential: 'include',
          method: 'GET',
        }),
        providesTags: [{ type: 'Statistics', id: 'LIST' }],
      }),
    };
  },
});

export const { useGetStatisticsQuery } = statisticsApi;
