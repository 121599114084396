import { Client } from 'config/types';
import { appApi } from './app-api-slice';

export const clientApi = appApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getClients: builder.query<Client[], void>({
        query: () => ({
          url: `/client`,
          method: 'GET',
          credential: 'include',
        }),
        providesTags: [{ type: 'Client', id: 'LIST' }],
      }),
      processRequest: builder.mutation<Client, Partial<Client>>({
        query: (body) => ({
          url: `/client`,
          method: 'POST',
          credential: 'include',
          body: body,
        }),
        invalidatesTags: [{ type: 'Client', id: 'LIST' }],
      }),
    };
  },
});

export const { useProcessRequestMutation, useGetClientsQuery } = clientApi;
