/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from 'react';

import { Card, Row, Col, Statistic, Select, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCirclePlus, faSignature, faUser, faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { apiKeySelector, currentClientSelector } from 'slices/app-slice';
import { useSelector } from 'react-redux';
import { useGetStatisticsQuery } from 'slices/statistics-api-slice';
import { Statistics } from 'config/types';
import dayjs from 'dayjs';

const { Title } = Typography;

type SelectType = {
  label: string;
  value: string;
};
export const OverviewPage: React.FC = () => {
  const apiKey = useSelector(apiKeySelector);
  const currentClient = useSelector(currentClientSelector);
  const [currMonth, setCurrMonth] = useState<Statistics>({});
  const [options, setOptions] = useState<SelectType[]>([]);

  const { data: statisticsData } = useGetStatisticsQuery(null, {
    skip: !apiKey,
  });
  useEffect(() => {
    // debugger;
    if (statisticsData && currentClient) {
      let monthTabTmp: SelectType[] = [];

      let first = false;
      statisticsData?.result
        ?.filter((statistic) => statistic.clientKey === currentClient.clientKey)
        ?.forEach((statistic) => {
          let date = dayjs(`${statistic.month}-01`).format('MMMM YYYY');
          monthTabTmp.push({
            value: statistic.month,
            label: date,
          });
          if (!first) {
            setCurrMonth(statistic);
            first = true;
          }
        });
      setOptions(monthTabTmp);
    }
  }, [statisticsData, currentClient]);
  const handleChange = (e: any) => {
    setCurrMonth(
      statisticsData?.result
        ?.filter((statistic) => statistic.clientKey === currentClient.clientKey)
        ?.find((statistic) => statistic.month === e)
    );
  };
  return (
    <>
      <Row style={{ margin: '0 0 16px 0' }}>
        <Col>
          <Title
            level={3}
            style={{
              margin: '0',
              display: 'flex',
              textAlign: 'center',
            }}
          >
            Overview
          </Title>
        </Col>
        <Col flex={'auto'} />
      </Row>
      <Row gutter={16} style={{ marginBottom: 32 }}>
        <Col span={24}>
          <Select
            placeholder="Month"
            style={{ width: '100%' }}
            onChange={handleChange}
            value={currMonth.month}
            options={options}
          />
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: 32 }}>
        <Col span={12}>
          <Card>
            <Statistic
              title="Total users"
              value={currMonth.user}
              prefix={<FontAwesomeIcon icon={faUser} size="xs" />}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <Statistic
              title="Active users"
              value={currMonth.user}
              prefix={<FontAwesomeIcon icon={faUserCheck} size="xs" />}
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={16} style={{ marginBottom: 32 }}>
        <Col span={12}>
          <Card>
            <Statistic
              title="Documents created"
              value={currMonth.count}
              prefix={<FontAwesomeIcon icon={faFileCirclePlus} size="xs" />}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <Statistic
              title="Documents signed"
              value={currMonth.sign}
              prefix={<FontAwesomeIcon icon={faSignature} size="xs" />}
            />
          </Card>
        </Col>
      </Row>
    </>
  );
};
