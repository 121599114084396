import { PdfFormData } from 'config/types';
import { appApi } from './app-api-slice';

export const pdfApi = appApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      uploadthingPdfByControlLink: builder.mutation<
        PdfFormData,
        Partial<PdfFormData>
      >({
        query: ({ controlLink, data }) => ({
          url: `/uploadthing_pdf?controlLink=${controlLink}`,
          method: 'POST',
          credential: 'include',
          body: data,
        }),
        invalidatesTags: [{ type: 'ContractPDF', id: 'LIST' }],
      }),
    };
  },
});

export const { useUploadthingPdfByControlLinkMutation } = pdfApi;
