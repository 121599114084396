import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadingSelector,
  pdfFileLoadSelector,
  setAppLoading,
  setPdfFileLoad,
  setTemplateText,
  setTimelineItems,
  setTimelineItemsBlock,
  setView,
} from 'slices/app-slice';
import {
  Space,
  Card,
  Typography,
  Segmented,
  Button,
  Tag,
  Popover,
  Image,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileContract } from '@fortawesome/free-solid-svg-icons';
import { ContractTypes, Stages, TimelineBlock } from 'config/enums';
import { TimelineItems } from 'config/types';
import { docx2html, nextStage } from 'utils/data-helper';
import {
  useGetContractTypeTemplateMutation,
  useGetContractTypesQuery,
} from 'slices/contract-type-api-slice';
import { SegmentedLabeledOption } from 'antd/es/segmented';
import useSaveArrayBuffer from 'hooks/use-save-array-buffer';

type Props = {
  stage: TimelineItems;
};
export const ChooseContractBlock = ({ stage }: Props) => {
  const dispatch = useDispatch();
  const { Title, Text } = Typography;
  const { setArrayBuffer } = useSaveArrayBuffer();
  const templateRef = useRef(0);
  const templateLoading = useSelector(loadingSelector);
  const pdfFileLoad = useSelector(pdfFileLoadSelector);
  const [options, setOptions] = useState<SegmentedLabeledOption[]>([]);
  const [createDisable, setCreateDisable] = useState(true);
  const [btnName, setBtnName] = useState('Create contract');

  const [getTemplate] = useGetContractTypeTemplateMutation();

  const { data: contractTypesData } = useGetContractTypesQuery();

  useEffect(() => {
    if (contractTypesData && contractTypesData.length > 0) {
      let optionsTmp = contractTypesData.map((contractTypeData) => {
        if (contractTypeData.icon) {
          const PopoverContent = (
            <div style={{ maxWidth: '250px' }}>
              <p>{contractTypeData.description}</p>
              <Image
                width={250}
                height={250}
                style={{ borderRadius: '8px' }}
                preview={false}
                src={contractTypeData.imgUrl}
              />
            </div>
          );

          return {
            label: (
              <Popover content={PopoverContent}>
                <div
                  style={{
                    paddingTop: '8px',
                    width: 100,
                    whiteSpace: 'normal',
                    lineHeight: '20px',
                  }}
                >
                  <Tag
                    style={{ margin: '4px 0' }}
                    color={
                      contractTypeData.color
                        ? contractTypeData.color
                        : 'magenta'
                    }
                  >
                    {contractTypeData.categoryName}
                  </Tag>
                  <div style={{ padding: '4px 0' }}>
                    {contractTypeData.name}
                  </div>
                </div>
              </Popover>
            ),
            value: contractTypeData.id,
          };
        } else {
          return {
            label: (
              <div
                style={{
                  paddingTop: '8px',
                  width: 100,
                  whiteSpace: 'normal',
                  lineHeight: '20px',
                }}
              >
                <Tag
                  style={{ margin: '4px 0' }}
                  color={
                    contractTypeData.color ? contractTypeData.color : 'magenta'
                  }
                >
                  {contractTypeData.categoryName}
                </Tag>
                <div style={{ padding: '4px 0' }}>{contractTypeData.name}</div>
              </div>
            ),
            value: contractTypeData.id,
          };
        }
      });
      setOptions(optionsTmp);
    }
  }, [contractTypesData]);

  const handleChoose = async (e: any) => {
    if (
      e.toString() === ContractTypes.DOCX.toString() ||
      e.toString() === ContractTypes.PDF.toString()
    ) {
      setBtnName('Upload file');
    } else {
      setBtnName('Create contract');
    }
    templateRef.current = e;
    if (createDisable) {
      setCreateDisable(false);
    }
  };
  const handleCreate = async () => {
    dispatch(setAppLoading(true));
    let input = null;
    switch (templateRef.current.toString()) {
      case ContractTypes.DOCX.toString():
        input = document.createElement('input');
        input.type = 'file';
        input.accept =
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

        input.onchange = (e: any) => {
          // debugger;
          let file = e.target.files[0];
          let reader = new FileReader();
          reader.readAsArrayBuffer(file);
          reader.onload = (readerEvent) => {
            docx2html(
              readerEvent.target.result as ArrayBuffer,
              (payload: any) => {
                debugger;
                dispatch(setView(templateRef.current));
                const timelineTmp = nextStage(Stages.EDIT_CONTRACT);
                dispatch(setTimelineItems(timelineTmp));
                dispatch(
                  setTimelineItemsBlock(TimelineBlock.CHOOSE_TYPE_BLOCK)
                );
                dispatch(setAppLoading(false));
                if (payload) {
                  dispatch(setTemplateText(payload));
                }
              }
            );
          };
        };

        input.click();
        break;
      case ContractTypes.PDF.toString():
        // debugger;
        input = document.createElement('input');
        input.type = 'file';
        input.accept = 'application/pdf';

        input.onchange = (e: any) => {
          debugger;
          let file = e.target.files[0];
          const fileSize = Math.round(file.size / 1048576);
          if (fileSize > 15) {
            alert('File too big, please select a file less than 15mb');
            return;
          }
          let reader = new FileReader();
          reader.readAsArrayBuffer(file);
          reader.onload = async (readerEvent) => {
            // debugger;
            dispatch(setView(templateRef.current));
            const timelineTmp = nextStage(Stages.EDIT_CONTRACT);
            dispatch(setTimelineItems(timelineTmp));
            dispatch(setTimelineItemsBlock(TimelineBlock.PDF_BLOCK));
            const arrayBuffer = readerEvent.target.result as ArrayBuffer;

            // const blob = new Blob([arrayBuffer.slice(0)], {
            //   type: file.type,
            // });
            await setArrayBuffer('pdfFile', arrayBuffer);
            await setArrayBuffer('pdfFileOriginal', arrayBuffer);
            dispatch(setPdfFileLoad(pdfFileLoad + 1));
            // dispatch(setPdfFileUpload(blob));
            dispatch(setAppLoading(false));
          };
        };

        input.click();
        break;
      default:
        await getTemplate({ id: templateRef.current })
          .unwrap()
          .then((payload) => {
            dispatch(setView(templateRef.current));
            const timelineTmp = nextStage(Stages.EDIT_CONTRACT);
            dispatch(setTimelineItems(timelineTmp));
            dispatch(setTimelineItemsBlock(TimelineBlock.CHOOSE_TYPE_BLOCK));
            dispatch(setAppLoading(false));
            if (payload.templateText) {
              dispatch(setTemplateText(payload.templateText));
            }
          });
        break;
    }
  };
  return (
    <Card style={{ opacity: stage.opacity }} bordered={true}>
      <Space direction="vertical" size={16} style={{ display: 'flex' }}>
        <Space direction="vertical" size={2}>
          <Title level={4} style={{ margin: '0' }}>
            Select a document type or upload your file
          </Title>
          <Text type="secondary">This will speed up the drafting process.</Text>
        </Space>
        <Segmented
          // disabled={stage.disable}
          options={options}
          onChange={handleChoose}
        />
        <Button
          type="primary"
          disabled={createDisable}
          icon={<FontAwesomeIcon icon={faFileContract} />}
          onClick={handleCreate}
          loading={
            btnName.includes('Create document')
              ? false
              : templateLoading
              ? true
              : false
          }
        >
          {btnName}
        </Button>
      </Space>
    </Card>
  );
};
