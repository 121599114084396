/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { CodeBlock, CopyBlock, monoBlue } from 'react-code-blocks';
import './components-page.module.css';
import { Space, Typography, Button, Card, Image, Tooltip, Drawer, Row, Col, Alert } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faEarthEurope } from '@fortawesome/free-solid-svg-icons';
import { apiKeySelector, currentClientSelector } from 'slices/app-slice';

const { Title, Text } = Typography;

export const ComponentsPage: React.FC = () => {
  const [open, setOpen] = useState(false);
  const apiKey = useSelector(apiKeySelector);
  const currentClient = useSelector(currentClientSelector);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
<Row style={{ margin: '0 0 16px 0' }}>
        <Col>
          <Title
            level={3}
            style={{
              margin: '0',
              display: 'flex',
              textAlign: 'center',
            }}
          >
            React Components
          </Title>
        </Col>
        <Col flex={'auto'} />
        <Col>
          <Space>
            <Tooltip title='Open more info'>
              <Button icon={<FontAwesomeIcon icon={faCode} />} onClick={showDrawer}>Embed</Button>
            </Tooltip>
            <Drawer title="Embedded UI" onClose={onClose} open={open} width={450}>
              <Space direction='vertical'>
              <Alert message="Sendforsign is built to be embedded into your app's interface, if needed. 
              Embed React UI Components, match to your brand, then deploy to your own domain." type='info' />
              <Title level={5}>
                Installation
              </Title>
              <Card style={{ overflow: 'auto', background: '#eaeef3'}}>
                    <CodeBlock
                      language="javascript"
                      text={`npm i sendforsign`}
                      theme={monoBlue}
                      showLineNumbers={false}
                    />
              </Card>
              <Title level={5}>
                Usage
              </Title>
              <Card style={{ overflow: 'auto', background: '#eaeef3'}}>
                    <CodeBlock
                      language="javascript"
                      text={`import { ContractEditor } from 'sendforsign';

<ContractEditor token='YourClientToken' />`}
                      theme={monoBlue}
                      showLineNumbers={false}
                    />
              </Card>
              <Title level={5}>
                Run the application
              </Title>
              <Card style={{ overflow: 'auto', background: '#eaeef3'}}>
                    <CodeBlock
                      language="javascript"
                      text={`npm run start`}
                      theme={monoBlue}
                      showLineNumbers={false}
                    />
              </Card>
              <Button block href='https://about.sendforsign.com/sdk/introsdk' target='_blank'>Learn more</Button>
              </Space>
            </Drawer>
          </Space>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
      <Col span={12}>
        <Card
          title={<Text>Contract list</Text>}
          type="inner"
          actions={[
            <Button
              type="text"
              icon={<FontAwesomeIcon icon={faEarthEurope} size="sm" style={{paddingRight: 4}}/>}
              href={`https://components.sendforsign.com/?path=/story/marbella-contractlist--primary&args=apiKey:${apiKey};clientKey:${currentClient.clientKey}`}
              target="_blank"
            >
              Live Demo
            </Button>,
            <Button
              type="text"
              icon={<FontAwesomeIcon icon={faCode} style={{paddingRight: 4}}/>}
              href="https://about.sendforsign.com/sdk/components/contractlist"
              target="_blank"
            >
              Install
            </Button>,
          ]}
        >
          <div style={{ height: 200, overflow: 'auto' }}>
            <Image
              preview={false}
              src="https://mintlify.s3-us-west-1.amazonaws.com/sendforsign/sdk/img/list.png"
            />
          </div>
        </Card>
        </Col>
        <Col span={12}>
        <Card
          title={<Text>Contract editor</Text>}
          type="inner"
          actions={[
            <Button
              type="text"
              icon={<FontAwesomeIcon icon={faEarthEurope} style={{paddingRight: 4}}/>}
              href={`https://components.sendforsign.com/?path=/story/marbella-contracteditor--primary&args=apiKey:${apiKey};clientKey:${currentClient.clientKey}`}
              target="_blank"
            >
              Live Demo
            </Button>,
            <Button
              type="text"
              icon={<FontAwesomeIcon icon={faCode} style={{paddingRight: 4}}/>}
              href="https://about.sendforsign.com/sdk/components/contracteditor"
              target="_blank"
            >
              Install
            </Button>,
          ]}
        >
          <div style={{ height: 200, overflow: 'auto' }}>
            <Image
              preview={false}
              src="https://mintlify.s3-us-west-1.amazonaws.com/sendforsign/sdk/img/editor.png"
            />
          </div>
        </Card>
        </Col>
        </Row>
        <Row gutter={[16, 16]} style={{marginTop: 16}}>
        <Col span={12}>
        <Card
          title={<Text>Template list</Text>}
          type="inner"
          actions={[
            <Button
              type="text"
              icon={<FontAwesomeIcon icon={faEarthEurope} size="sm" style={{paddingRight: 4}}/>}
              href={`https://components.sendforsign.com/?path=/story/marbella-templatelist--primary&args=apiKey:${apiKey};clientKey:${currentClient.clientKey}`}
              target="_blank"
            >
              Live Demo
            </Button>,
            <Button
              type="text"
              icon={<FontAwesomeIcon icon={faCode} size="sm" style={{paddingRight: 4}}/>}
              href="https://about.sendforsign.com/sdk/components/templatelist"
              target="_blank"
            >
              Install
            </Button>,
          ]}
        >
          <div style={{ height: 200, overflow: 'auto' }}>
            <Image
              preview={false}
              src="https://mintlify.s3-us-west-1.amazonaws.com/sendforsign/sdk/img/t-list.png"
            />
          </div>
        </Card>
        </Col>
        <Col span={12}>
        <Card
          title={<Text>Template editor</Text>}
          type="inner"
          actions={[
            <Button
              type="text"
              icon={<FontAwesomeIcon icon={faEarthEurope} style={{paddingRight: 4}}/>}
              href={`https://components.sendforsign.com/?path=/story/marbella-templateeditor--primary&args=apiKey:${apiKey};clientKey:${currentClient.clientKey}`}
              target="_blank"
            >
              Live Demo
            </Button>,
            <Button
              type="text"
              icon={<FontAwesomeIcon icon={faCode} size="sm" style={{paddingRight: 4}}/>}
              href="https://about.sendforsign.com/sdk/components/templateeditor"
              target="_blank"
            >
              Install
            </Button>,
          ]}
        >
          <div style={{ height: 200, overflow: 'auto' }}>
            <Image
              preview={false}
              src="https://mintlify.s3-us-west-1.amazonaws.com/sendforsign/sdk/img/template.png"
            />
          </div>
        </Card>
        </Col>
        </Row>
    </>
  );
};
