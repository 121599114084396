import { Stripe } from 'config/types';
import { appApi } from './app-api-slice';

export const stripeApi = appApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      createStripeSession: builder.mutation<Stripe, Partial<Stripe>>({
        query: (data) => ({
          url: `/create_stripe_session`,
          method: 'POST',
          credential: 'include',
          body: data,
        }),
        invalidatesTags: [{ type: 'Stripe', id: 'LIST' }],
      }),
    };
  },
});

export const { useCreateStripeSessionMutation } = stripeApi;
