export const prepareHeaders = (headers: Headers, { endpoint }) => {
  const UPLOAD_ENDPOINTS = [
    'uploadthingPdfByControlLink',
    'sendEmailsSignPdfByControlLink',
    'sendEmailsSignPdfByShareLink',
  ];
  const API_ENDPOINTS = [
    'getClients',
    'getUsers',
    'getWebhooks',
    'createWebhook',
    'updateWebhook',
    'deleteWebhook',
    'getWebhookEvents',
    'getContractsByUserId',
    'getWebhookByWebhookKey',
    'getStatistics',
  ];
  if (!UPLOAD_ENDPOINTS.includes(endpoint)) {
    headers.set('Accept', 'application/json');
    headers.set('content-type', 'application/json');
  }
  if (
    API_ENDPOINTS.includes(endpoint) &&
    JSON.parse(localStorage.getItem('apiKey'))
  ) {
    headers.set(
      'x-sendforsign-key',
      JSON.parse(localStorage.getItem('apiKey'))
    );
  }
  return headers;
};
export const readBase64 = async (response: any) => {
  return new Promise((resolve, reject) => {
    response.blob().then((blob) => {
      let reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        debugger;
        let base64data = reader.result;
        // console.log(base64data);
        resolve(base64data);
      };
    });
  });
};
