import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  addBtnVisibleSelector,
  approveDisableSelector,
  contractSelector,
  contractValueSelector,
  setApproveModal,
  setContract,
  setNotification,
  setSignModal,
  setTextState,
  setTimelineItemsBlock,
  signDisableSelector,
  textSelector,
  viewSelector,
} from 'slices/app-slice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSignature,
  faSquarePlus,
  faArrowRight,
  faStamp,
} from '@fortawesome/free-solid-svg-icons';
import { Space, Card, Typography, Button, Tooltip, Divider } from 'antd';
import { TimelineBlock, ShareLinkView, ContractTypes } from 'config/enums';
import { useAuth } from '@clerk/clerk-react';

import { TimelineItems } from 'config/types';
import {
  useCheckContractValueMutation,
  useCreateContractMutation,
  useSaveContractValueMutation,
} from 'slices/contract-api-slice';
import { useHistory } from 'react-router-dom';
import {
  useCreateShareLinkMutation,
  useGetShareLinksQuery,
} from 'slices/contract-share-link-api-slice';
import { ShareLinkLine } from './share-link-line/share-link-line';
import { useUploadthingPdfByControlLinkMutation } from 'slices/pdf-api-slice';
import useSaveArrayBuffer from 'hooks/use-save-array-buffer';
type Props = {
  stage: TimelineItems;
};
type ShareLines = {
  id?: number;
  shareLink?: string;
  view?: ShareLinkView;
};
export const ShareLinkBlock = ({ stage }: Props) => { 
  const dispatch = useDispatch();
  const { getArrayBuffer } = useSaveArrayBuffer();
  const { isLoaded, userId, sessionId, getToken } = useAuth();

  const view = useSelector(viewSelector);
  const contract = useSelector(contractSelector);
  const contractValue = useSelector(contractValueSelector);
  // const createContractFlag = useSelector(createContractSelector);
  const textState = useSelector(textSelector);
  const signDisable = useSelector(signDisableSelector);
  const addBtnVisible = useSelector(addBtnVisibleSelector);
  const approveDisable = useSelector(approveDisableSelector);
  // const pdfFile = useSelector(pdfFileUploadSelector);

  const [signSpin, setSignSpin] = useState(false);
  const [approveSpin, setApproveSpin] = useState(false);
  const [addBtnSpin, setAddBtnSpin] = useState(false);
  const [copyVisible, setCopyVisible] = useState(false);
  const [shareLines, setShareLines] = useState<ShareLines[]>([]);
  const [first, setFirst] = useState(false);
  const { Title, Text, Link } = Typography;

  const [createContract] = useCreateContractMutation();
  const [saveValue] = useSaveContractValueMutation();
  const [checkContractValue] = useCheckContractValueMutation();
  const [createShareLink] = useCreateShareLinkMutation();
  const [uploadPdf] = useUploadthingPdfByControlLinkMutation();
  const { data: shareLinksData } = useGetShareLinksQuery(
    { controlLink: contract.controlLink, userId: userId },
    {
      skip: contract.controlLink ? false : true,
    }
  );

  useEffect(() => {
    if (contract && contract.shareLink) {
      if (!first) {
        setFirst(true);
        // setShareLink(`${window.location.origin}/sharing/${contract.shareLink}`);
        setCopyVisible(true);
        dispatch(
          setTimelineItemsBlock(
            contract.contractType.toString() === ContractTypes.PDF.toString()
              ? TimelineBlock.SHARE_LINK_PDF_BLOCK
              : TimelineBlock.SHARE_LINK_BLOCK
          )
        );
      }
    }
  }, [contract]);
  useEffect(() => {
    if (shareLinksData) {
      let shareLinesTmp: ShareLines[] = [];
      shareLinksData.forEach((shareLinkData) => {
        shareLinesTmp.push({
          id: shareLinkData.id,
          shareLink: shareLinkData.shareLink,
          view: shareLinkData.view,
        });
      });
      setShareLines(shareLinesTmp);
    }
  }, [shareLinksData]);

  const handleCreateLink = async (event: any) => {
    let controlLink = '';
    let shareLink = '';
    event.currentTarget.disabled = true;
    debugger;
    await createContract({ contractType: view, userId: userId })
      .unwrap()
      .then((payload) => {
        controlLink = payload.controlLink;
        shareLink = payload.shareLink;
      });
    if (view.toString() === ContractTypes.PDF.toString()) {
      const formData = new FormData();
      const pdfFile = (await getArrayBuffer('pdfFile')) as ArrayBuffer;
      const pdfFileBlob = new Blob([pdfFile], { type: 'application/pdf' });
      formData.append('pdf', pdfFileBlob);
      await uploadPdf({
        controlLink: controlLink,
        userId: userId,
        data: formData,
      }).then(() => {
        dispatch(
          setContract({
            contractType: view,
            controlLink: controlLink,
            shareLink: shareLink,
          })
        );
        // history.push(`/control/${controlLink}`);
        if (userId) {
          window.history.pushState(
            { path: `/user-control/${controlLink}` },
            '',
            `/user-control/${controlLink}`
          );
        } else {
          window.history.pushState(
            { path: `/control/${controlLink}` },
            '',
            `/control/${controlLink}`
          );
        }
      });
    } else {
      await saveValue({
        controlLink: controlLink,
        contractValue: textState,
        userId: userId,
      }).then(() => {
        // dispatch(setCreateContract(true));
        dispatch(setTextState(''));
        dispatch(
          setContract({
            contractType: view,
            controlLink: controlLink,
            shareLink: shareLink,
            contractValue: textState,
          })
        );
        // if (userId) {
        //   history.push(`/user-control/${controlLink}#part-3`);
        // } else {
        //   history.push(`/control/${controlLink}#part-3`);
        // }
      });
    }
  };
  const handleSign = async () => {
    setSignSpin(true);
    let changed = false;
    await checkContractValue({
      // shareLink: contract.shareLink,
      controlLink: contract.controlLink,
      changeTime: contractValue.changeTime,
      userId: userId,
      owner: true,
    })
      .unwrap()
      .then((payload) => {
        setSignSpin(false);
        changed = payload.changed;
      });
    if (!changed) {
      dispatch(setSignModal(true));
    } else {
      dispatch(
        setNotification({
          text: 'Contract updated. You must reload your page',
        })
      );
    }
  };
  const handleApprove = async () => {
    setApproveSpin(true);
    let changed = false;
    await checkContractValue({
      // shareLink: contract.shareLink,
      controlLink: contract.controlLink,
      changeTime: contractValue.changeTime,
      userId: userId,
      owner: true,
    })
      .unwrap()
      .then((payload) => {
        setApproveSpin(false);
        changed = payload.changed;
      });
    if (!changed) {
      dispatch(setApproveModal(true));
    } else {
      dispatch(
        setNotification({
          text: 'Contract updated. You must reload your page',
        })
      );
    }
  };
  const handleAddShareLink = async () => {
    setAddBtnSpin(true);
    await createShareLink({
      controlLink: contract.controlLink,
      userId: userId,
    }).then(() => {
      setAddBtnSpin(false);
    });
  };

  return (
    <>
      <Card
        id="part-3"
        bordered={true}
        style={{ maxWidth: '1200px', opacity: stage.opacity }}
      >
        <Space direction="vertical" size={16} style={{ display: 'flex' }}>
          <Space direction="vertical" size={2} style={{ maxWidth: '600px' }}>
            <Title level={4} style={{ margin: '0 0 0 0' }}>
              Share, sign, approve, and more
            </Title>
            <Text type="secondary">
              See what's possible to do with your document.
            </Text>
          </Space>
          {!copyVisible && (
            <Space direction="vertical" style={{ maxWidth: '240px' }}>
              <Button
                type="primary"
                icon={<FontAwesomeIcon icon={faArrowRight} />}
                onClick={handleCreateLink}
              >
                Continue
              </Button>
              <Text type="secondary" style={{ fontSize: 12, opacity: 0.6 }}>
                By using Sendforsign, I agree to its{' '}
                <Link
                  style={{ fontSize: 12 }}
                  href="https://about.sendforsign.com/about/termsandconditions"
                  target="_blank"
                >
                  Terms and Conditions
                </Link>{' '}
                and{' '}
                <Link
                  style={{ fontSize: 12 }}
                  href="https://about.sendforsign.com/about/privacypolicy"
                  target="_blank"
                >
                  Privacy Policy
                </Link>
                .
              </Text>
            </Space>
          )}
          {copyVisible && (
            <>
              {shareLines &&
                shareLines.map((shareLine) => {
                  return (
                    <ShareLinkLine
                      controlLink={contract.controlLink}
                      shareLink={shareLine.shareLink}
                      id={shareLine.id}
                      view={shareLine.view}
                    />
                  );
                })}

              <Space style={{ marginBottom: '24px' }}>
                <Tooltip title="Sign the document from your side.">
                  <Button
                    id="signContract"
                    type="default"
                    icon={<FontAwesomeIcon icon={faSignature} />}
                    onClick={handleSign}
                    disabled={signDisable}
                    loading={signSpin}
                  >
                    Sign
                  </Button>
                </Tooltip>
                <Tooltip title="Approve the document from your side.">
                  <Button
                    id="ApproveContract"
                    type="default"
                    icon={<FontAwesomeIcon icon={faStamp} />}
                    onClick={handleApprove}
                    disabled={approveDisable}
                    loading={approveSpin}
                    // disabled={disableSign}
                  >
                    Approve
                  </Button>
                </Tooltip>
                {addBtnVisible && (
                  <Tooltip title="Add another link to this contract.">
                    <Button
                      icon={<FontAwesomeIcon icon={faSquarePlus} />}
                      onClick={handleAddShareLink}
                      loading={addBtnSpin}
                    ></Button>
                  </Tooltip>
                )}
              </Space>
            </>
          )}
        </Space>
      </Card>
    </>
  );
};
