import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { store } from 'store';
import { Routes } from 'routes';

const WrappedApp = () => (
  <Provider store={store}>
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  </Provider>
);
export function initialize() {
  const container = document.getElementById('root');
  ReactDOM.render(<WrappedApp />, container);
}
