export enum StatusColor {
  ACTIVE = 'blue',
  WAIT = 'gray',
  SUCCESS = 'green',
}
export enum FeatureStatus {
  ADDED = 'Added',
  NOT_ADDED = 'Not added',
  NOT_SELECTED = 'Not selected',
  SELECTED = 'Selected',
}
export enum FeaturePrice {
  PAID = 'Paid',
  FREE = 'Free',
}
export enum Features {
  SIGN_2 = 1,
  SIGN_MANY = 2,
  SIGN_AI = 3,
  APPROVE_MANY = 4,
}
export enum Stages {
  CHOOSE_TYPE = 0,
  EDIT_CONTRACT = 1,
  CREATE_LINK = 2,
  CLEAR = 99,
}
export enum StagesModal {
  MODAL_DATA = 0,
  MODAL_SIGN = 1,
}
export enum TimelineBlock {
  CONTRACT_TYPE_BLOCK = 'ContractType',
  CHOOSE_TYPE_BLOCK = 'ChooseType',
  EDITOR_BLOCK = 'Editor',
  SHARE_LINK_BLOCK = 'ShareLink',
  CONTROL_LINK_BLOCK = 'ControlLink',
  PDF_BLOCK = 'Pdf',
  SHARE_LINK_PDF_BLOCK = 'ShareLinkPdf',
}
export enum StatusOpacity {
  ACTIVE = 1,
  WAIT = 0.2,
}
export enum ContractLink {
  CONTROL = 0,
  SHARE = 1,
  USER_CONTROL = 2,
  CREATE = 9,
}
export enum ContractStatus {
  FULLY_SIGNED = '6',
  SIGNED = '5',
  APPROVED = '4',
  SEEN = '3',
  SENT = '2',
  CREATED = '1',
}
export const enum ActionAnalytics {
  IDENTITY = 'identity',
  TRACK = 'track',
  PAGE = 'page',
}
export const enum ContractTypes {
  DOCX = 6,
  PDF = 7,
}
export const enum ShareLinkView {
  SIGN = '1',
  APPROVE = '2',
  LOCK = '3',
  VIEW = '4',
}
export const enum EventStatuses {
  FULLY_SIGNED = '6',
  SIGNED = '5',
  APPROVED = '4',
  SEEN = '3',
  SENT = '2',
  CREATED = '1',
}
export const enum ContractAction {
  SIGN = 'sign',
  APPROVE = 'approve',
}

export const enum Action {
  READ = 'read',
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
  LIST = 'list',
  SEND = 'send',
}
export const enum PlaceholderView {
  OTHER = 1,
  SIGNATURE = 2,
}
export const enum Tags {
  PLACEHOLDER = 'placeholder',
  DATE = 'date',
  FULLNAME = 'fullname',
  EMAIL = 'email',
  SIGN = 'sign',
  INITIALS = 'initials',
}
export const enum SpecialType {
  DATE = 1,
  FULLNAME = 2,
  EMAIL = 3,
  SIGN = 4,
  INITIALS = 5,
}
