import React, { Children, useState } from 'react';
import './menu.module.css';
import type { MenuProps } from 'antd';
import { Layout, Image, Menu, theme, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRightArrowLeft,
  faFileContract,
  faHouse,
  faKey,
  faPuzzlePiece,
  faSliders,
  faTrowelBricks,
} from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

const { Header, Content, Footer, Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem('Overview', '/overview', <FontAwesomeIcon icon={faHouse} />),
  getItem('Documents', '/documents', <FontAwesomeIcon icon={faFileContract} />),
  getItem('Templates', '/templates', <FontAwesomeIcon icon={faTrowelBricks} />),
  // getItem('Portal', '/portal', <FontAwesomeIcon icon={faBullhorn} />),
  getItem('API Keys', '/api', <FontAwesomeIcon icon={faKey} />),
  getItem(
    'Webhooks',
    '/webhooks',
    <FontAwesomeIcon icon={faArrowRightArrowLeft} />
  ),
  getItem(
    'Components',
    '/components',
    <FontAwesomeIcon icon={faPuzzlePiece} />
  ),
  getItem('Settings', '/settings', <FontAwesomeIcon icon={faSliders} />),
];

export const SideMenu = () => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const history = useHistory();
  const handleMenuClick = ({ key }) => {
    if (key) {
      history.push(key);
    }
  };

  return (
    <Sider
      style={{
        // overflow: 'auto',
        // height: '100vh',
        // position: 'fixed',
        // left: 0,
        // top: 0,
        // bottom: 0,
        // background: '#f5f5f5',
        borderInlineEnd: '1px solid #f0f0f0',
      }}
      breakpoint="lg"
      collapsedWidth="0"
      theme='light'
    >
      <Image
        style={{ margin: '16px 24px 24px 24px' }}
        width={140}
        preview={false}
        src="https://mintlify.s3-us-west-1.amazonaws.com/sendforsign/logo/light.svg"
      />
      <Menu
        mode="inline"
        defaultSelectedKeys={['1']}
        items={items}
        onClick={handleMenuClick}
        style={{ borderInlineEnd: 'none' }}
      />
    </Sider>
  );
};
