import { User } from 'config/types';
import { appApi } from './app-api-slice';

export const subclientApi = appApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getUsers: builder.query<User[], void>({
        query: () => ({
          url: `/user`,
          method: 'GET',
          credential: 'include',
        }),
        providesTags: [{ type: 'Subclient', id: 'LIST' }],
      }),
    };
  },
});

export const { useGetUsersQuery } = subclientApi;
