import React, { useEffect, useState } from 'react';
import {
  Timeline,
  Space,
  Typography,
  Button,
  Tag,
  Row,
  Col,
  Divider,
  Image,
  Anchor,
} from 'antd';
import { TwitterOutlined, LinkedinFilled } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faCode, faComment } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  contractSelector,
  setTimelineItems,
  setTimelineItemsBlock,
  timelineItemsBlockSelector,
  timelineItemsSelector,
} from 'slices/app-slice';
import { Stages, TimelineBlock } from 'config/enums';
import { nextStage } from 'utils/data-helper';
import { timelineItemsBlock } from 'utils/timeline-block/timeline-block';
import { useHistory } from 'react-router-dom';
import { useAuth, SignInButton } from '@clerk/clerk-react';
import { SignModal } from 'components/sign-modal/sign-modal';
import { ApproveModal } from 'components/approve-modal/approve-modal';
import { ResultModal } from 'components/result-modal/result-modal';
import { Notification } from 'components/notification/notification';

const { Title, Text, Link } = Typography;

export const ContractPage: React.FC = () => {
  const dispatch = useDispatch();
  const contract = useSelector(contractSelector);
  const timelineItems = useSelector(timelineItemsSelector);
  const currTimelineItem = useSelector(timelineItemsBlockSelector);
  const [visibleAnchor, setVisibleAnchor] = useState(false);
  const history = useHistory();
  const { userId } = useAuth();

  // console.log('user', user);
  useEffect(() => {
    // console.log('ContractPage');
    dispatch(setTimelineItemsBlock(TimelineBlock.CONTRACT_TYPE_BLOCK));
    dispatch(setTimelineItems(nextStage(Stages.CLEAR)));
  }, []);
  useEffect(() => {
    if (contract && contract.controlLink) {
      setVisibleAnchor(true);
    }
  }, [contract]);

  if (!timelineItems || timelineItems.length === 0) {
    return;
  }
  return (
    <>
      <Row align="middle" style={{ padding: '0 8px 8px 8px' }}>
        <Col style={{ padding: '8px 0' }}>
          <Space>
            <Image
              width={140}
              preview={false}
              src="https://mintlify.s3-us-west-1.amazonaws.com/sendforsign/logo/light.svg"
            />
            <Tag color="magenta">Beta</Tag>
          </Space>
        </Col>

        <Col flex={'auto'}></Col>
        <Col style={{ padding: '8px 0' }}>
          <Space size={4}>
            {!userId ? (
              <SignInButton mode="modal">
                <Button>Sign in</Button>
              </SignInButton>
            ) : (
              <Button href="/overview">Dashboard</Button>
            )}
            <Button
              type="text"
              icon={<FontAwesomeIcon icon={faCode} />}
              onClick={() => {
                history.push('/dev');
              }}
            >
              Contract API
            </Button>
            <Button
              type="text"
              icon={<FontAwesomeIcon icon={faComment} />}
              href="https://app.kaleido.so/addfeedback/4f0e7587-913e-4779-b214-4c28ca7cb2fe"
              target="_blank"
            >
              Feedback
            </Button>
            <Button
              type="text"
              icon={<FontAwesomeIcon icon={faBook} />}
              href="https://about.sendforsign.com/"
              target="_blank"
            >
              Docs
            </Button>
          </Space>
        </Col>
      </Row>
      <Divider style={{ paddingTop: '0', marginTop: '0' }} />

      <Row>
        <Col flex={'auto'}></Col>
        <Col span="24" style={{ maxWidth: '1200px' }}>
          {/* 
          -
          Mains block starts
          -
          */}

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span="24">
              <Space direction="vertical" style={{ display: 'flex' }}>
                <Space
                  direction="vertical"
                  size={4}
                  style={{ margin: '0 0 32px 0' }}
                >
                  <Title level={3} style={{ margin: '0 0 0 0' }}>
                    Draft, approve, send, sign documents seamlessly
                  </Title>
                  <Text type="secondary">
                    Follow the steps to prepare your document.
                  </Text>
                </Space>
                <Timeline
                  items={timelineItemsBlock({
                    type: '',
                    block: currTimelineItem,
                    timelineItems: timelineItems,
                  })}
                />
              </Space>
            </Col>
          </Row>
          {/* Block ends */}

          <Divider style={{ margin: '40px 0' }} />

          {/* 
          -
          Footer block starts
          -
          */}

          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col flex={'1 1 300px'}>
              <Space
                direction="vertical"
                style={{ display: 'flex', margin: '32px 0' }}
              >
                <Space
                  onClick={() => {
                    history.push('/');
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  <Image
                    width={140}
                    preview={false}
                    src="https://mintlify.s3-us-west-1.amazonaws.com/sendforsign/logo/light.svg"
                  />
                  <Tag color="magenta">Beta</Tag>
                </Space>
                <Space style={{ margin: '8px 0' }} size={16}>
                  <Link
                    href="https://www.linkedin.com/company/sendforsign/about/"
                    target="_blank"
                  >
                    <LinkedinFilled style={{ fontSize: '20px' }} />
                  </Link>
                  <Link href="https://twitter.com/sendforsign" target="_blank">
                    <TwitterOutlined style={{ fontSize: '20px' }} />
                  </Link>
                </Space>
              </Space>
            </Col>
            <Col flex={'1 1 300px'}>
              <Space
                direction="vertical"
                style={{ display: 'flex', margin: '32px 0' }}
              >
                <Link
                  href="https://about.sendforsign.com/about/vision"
                  target="_blank"
                >
                  <Text type="secondary">About Sendforsign</Text>
                </Link>
                <Link
                  href="https://about.sendforsign.com/changelog"
                  target="_blank"
                >
                  <Text type="secondary">Changelog</Text>
                </Link>
                <Link
                  href="https://about.sendforsign.com/introduction"
                  target="_blank"
                >
                  <Text type="secondary">Documentation</Text>
                </Link>
              </Space>
            </Col>
            <Col flex={'1 1 300px'}>
              <Space
                direction="vertical"
                style={{ display: 'flex', margin: '32px 0' }}
              >
                <Link href="https://about.sendforsign.com/safe" target="_blank">
                  <Text type="secondary">Is it safe to use Sendforsign?</Text>
                </Link>
                <Link
                  href="https://about.sendforsign.com/essentials/first"
                  target="_blank"
                >
                  <Text type="secondary">How to create a document</Text>
                </Link>
                <Link
                  href="https://about.sendforsign.com/essentials/options"
                  target="_blank"
                >
                  <Text type="secondary">How to add more options</Text>
                </Link>
              </Space>
            </Col>
          </Row>

          {/* Block ends */}
        </Col>
        <Col flex={'auto'}></Col>
        <Col
          id="anchorCol"
          style={!visibleAnchor ? { display: 'none' } : { display: 'block' }}
        >
          <Anchor
            items={[
              {
                key: 'Timeline',
                href: '#part-1',
                title: 'Timeline',
              },
              {
                key: 'Document',
                href: '#part-2',
                title: 'Document',
              },
              {
                key: 'Actions',
                href: '#part-3',
                title: 'Actions',
              },
              {
                key: 'Control',
                href: '#part-4',
                title: 'Control',
              },
            ]}
          />
        </Col>
      </Row>
      <SignModal />
      <ApproveModal />
      <ResultModal />
      <Notification />
    </>
  );
};
