import React, { useEffect, useRef } from 'react';

import './settings-page.module.css';
import { Space, Typography, Tabs } from 'antd';
import type { TabsProps } from 'antd';
import { useState } from 'react';
import { Buffer } from 'buffer';
import { useCreateStripeSessionMutation } from 'slices/stripe-api-slice';
import { useSelector } from 'react-redux';
import { activePlanSelector, apiKeySelector } from 'slices/app-slice';
import { getSettingsTab } from 'utils/data-helper';

const { Title } = Typography;

export const SettingsPage: React.FC = () => {
  const activePlan = useSelector(activePlanSelector);
  const apiKey = useSelector(apiKeySelector);
  const inputValue = useRef(20);
  const [btnLoad, setBtnLoad] = useState(false);
  const [createSession] = useCreateStripeSessionMutation();

  const onSliderChange = (newValue) => {
    // setInputValue(newValue as number);
    inputValue.current = newValue as number;
    setItems(
      getSettingsTab(
        activePlan.active,
        newValue as number,
        btnLoad,
        onClick,
        onSliderChange
      )
    );
    console.log(newValue);
  };

  const onClick = async () => {
    setBtnLoad(true);
    if (activePlan.active) {
      let href = '';
      if (window.location.href.includes('app.sendforsign.com')) {
        href = 'https://billing.stripe.com/p/login/9AQ8zo0ii83J5na7ss';
      } else {
        href = 'https://billing.stripe.com/p/login/test_aEUaGu8N49do6Aw288';
      }
      window.location.href = href;
    } else {
      console.log('onClick', inputValue.current);
      const buffer = Buffer.from(apiKey).toString('base64');
      await createSession({ id: buffer, quantity: inputValue.current })
        .unwrap()
        .then((data) => {
          setBtnLoad(false);
          window.location.href = data.url;
        });
    }
  };

  const [items, setItems] = useState<TabsProps['items']>(
    getSettingsTab(
      activePlan.active,
      inputValue.current,
      btnLoad,
      onClick,
      onSliderChange
    )
  );

  useEffect(() => {
    if (activePlan.active) {
      inputValue.current = activePlan.quantity;
      setItems(
        getSettingsTab(
          activePlan.active,
          activePlan.quantity,
          btnLoad,
          onClick,
          onSliderChange
        )
      );
    }
  }, [activePlan]);

  return (
    <Space direction="vertical" style={{ display: 'flex' }}>
      <Space direction="vertical" size={4} style={{ margin: '0 0 16px 0' }}>
        <Title level={3} style={{ margin: '0' }}>
          Settings
        </Title>
      </Space>
      <Tabs defaultActiveKey="1" items={items} />
    </Space>
  );
};
