import React, { useEffect, useState } from 'react';
import './header.module.css';
import {
  Row,
  Col,
  Space,
  Button,
  Divider,
  Select,
  Alert,
  Dropdown,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBook,
  faCircleUser,
  faComment,
  faHouseUser,
  faListCheck,
  faRightFromBracket,
} from '@fortawesome/free-solid-svg-icons';
import { SignOutButton, UserButton, useUser } from '@clerk/clerk-react';
import { useGetClientsQuery } from 'slices/client-api-slice';
import { useHistory } from 'react-router-dom';
import type { MenuProps } from 'antd';
import {
  activePlanSelector,
  apiKeySelector,
  currentClientSelector,
  setCurrentClient,
} from 'slices/app-slice';
import { useDispatch, useSelector } from 'react-redux';
import { useGetStatisticsQuery } from 'slices/statistics-api-slice';
type SelectData = {
  value: string;
  label: string;
};
export const HeaderMenu = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const apiKey = useSelector(apiKeySelector);
  const activePlan = useSelector(activePlanSelector);
  const currentClient = useSelector(currentClientSelector);
  const [selectData, setSelectData] = useState<SelectData[]>([]);

  const { data: clientsData } = useGetClientsQuery(null, {
    skip: !apiKey,
  });
  const { data: statisticsData } = useGetStatisticsQuery(null, {
    skip: !apiKey,
  });

  useEffect(() => {
    if (clientsData && clientsData.length > 0) {
      let selectDataTmp: SelectData[] = [];
      clientsData?.forEach((clientData, index) => {
        if (index === 0 && !currentClient.clientKey) {
          dispatch(setCurrentClient(clientData));
        }
        selectDataTmp.push({
          label:
            clientData.organization ||
            clientData.fullname ||
            clientData.customKey ||
            clientData.clientKey,
          value: clientData.clientKey,
        });
      });
      setSelectData(selectDataTmp);
    }
  }, [clientsData]);

  const handleChange = (e: any) => {
    const clientFind = clientsData.find((client) => client.clientKey === e);
    if (clientFind) {
      dispatch(setCurrentClient(clientFind));
    }
  };

  const { user } = useUser();

  const dateConverter = (endDate) => {
    const newStartDate = user.createdAt;
    const newEndDate = endDate;
    const one_day = 1000 * 60 * 60 * 24;
    let result;
    result = Math.ceil(
      (newEndDate.getTime() - newStartDate.getTime()) / one_day
    );
    if (result < 0) {
      return 0;
    }
    return result;
  };

  const trialDays = dateConverter(new Date());
  const info = 'Day ' + trialDays + ' of your 14-day trial';

  const items: MenuProps['items'] = [
    {
      key: '1',
      icon: <FontAwesomeIcon icon={faHouseUser} />,
      label: (
        <a
          onClick={() => {
            let href = '';
            if (window.location.href.includes('app.sendforsign.com')) {
              href = 'https://accounts.sendforsign.com/user';
            } else {
              href = 'https://advanced-lizard-87.accounts.dev/user';
            }
            window.location.href = href;
          }}
        >
          Account
        </a>
      ),
    },
    {
      key: '2',
      icon: <FontAwesomeIcon icon={faListCheck} />,
      label: (
        <a
          onClick={() => {
            history.push('/onboarding');
          }}
        >
          Onboarding
        </a>
      ),
    },
    {
      key: '3',
      icon: <FontAwesomeIcon icon={faRightFromBracket} />,
      label: (
        <SignOutButton>
          <a>Logout</a>
        </SignOutButton>
      ),
    },
  ];

  return (
    <>
      <Row align="middle" style={{ padding: '8px 32px' }}>
        {activePlan && !activePlan.active && (
          <Col>
            <Alert
              message={info}
              type="info"
              style={{ height: 32 }}
              onClick={() => {
                history.push('/settings');
              }}
              action={
                <Button size="small" type="default" style={{ marginLeft: 12 }}>
                  Add billing
                </Button>
              }
            />
          </Col>
        )}
        <Col flex={'auto'}></Col>
        <Col style={{ padding: '8px 0' }}>
          <Space wrap>
            <Select
              placeholder="Client key"
              style={{ width: 120 }}
              options={selectData}
              onChange={handleChange}
              value={currentClient.clientKey}
            />
            <Button
              type="default"
              icon={<FontAwesomeIcon icon={faComment} />}
              href="https://airtable.com/app8jiZb9qU4pNdYb/pag9rl2atRJUkFjpS/form"
              target="_blank"
            >
              Feedback
            </Button>
            <Button
              type="default"
              icon={<FontAwesomeIcon icon={faBook} />}
              href="https://about.sendforsign.com/"
              target="_blank"
            >
              Docs
            </Button>
            {/* <UserButton afterSignOutUrl="/" showName/> */}
            <Dropdown
              menu={{ items }}
              placement="bottomRight"
              trigger={['click']}
            >
              <Button icon={<FontAwesomeIcon icon={faCircleUser} />}></Button>
            </Dropdown>
          </Space>
        </Col>
      </Row>
      <Divider style={{ padding: '0', margin: '0' }} />
    </>
  );
};
