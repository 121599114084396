import { Webhook } from 'config/types';
import { appApi } from './app-api-slice';

export const webhookApi = appApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getWebhooks: builder.query<Webhook[], void>({
        query: () => ({
          url: `/webhook`,
          credential: 'include',
          method: 'GET',
        }),
        providesTags: [{ type: 'Webhook', id: 'LIST' }],
      }),
      getWebhookByWebhookKey: builder.query<Webhook, Partial<Webhook>>({
        query: ({ clientKey, webhookKey }) => ({
          url: `/webhook?clientKey=${clientKey}&webhookKey=${webhookKey}`,
          credential: 'include',
        }),
        providesTags: [{ type: 'Webhook', id: 'LIST' }],
      }),
      getWebhook: builder.query<Webhook, string>({
        query: (id) => ({
          url: `/webhook?id=${id}`,
          credential: 'include',
        }),
        providesTags: [{ type: 'Webhook', id: 'LIST' }],
      }),
      createWebhook: builder.mutation<Webhook, Partial<Webhook>>({
        query: (body) => ({
          url: '/webhook',
          method: 'POST',
          credential: 'include',
          body: body,
        }),
        invalidatesTags: [{ type: 'Webhook', id: 'LIST' }],
      }),
      updateWebhook: builder.mutation<
        void,
        Pick<Webhook, 'id'> & Partial<Webhook>
      >({
        query: ({ id, ...patch }) => ({
          url: `/webhook?id=${id}`,
          method: 'PUT',
          credential: 'include',
          body: patch,
        }),
        async onQueryStarted({ id, ...patch }, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            webhookApi.util.updateQueryData(
              'getWebhook',
              'id',
              (draft: any) => {
                Object.assign(draft, patch);
              }
            )
          );
          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();
          }
        },
        invalidatesTags: [{ type: 'Webhook', id: 'LIST' }],
      }),
      deleteWebhook: builder.mutation<
        { success: boolean; data: Webhook },
        Webhook
      >({
        query(data) {
          return {
            url: `/webhook`,
            method: 'DELETE',
            credential: 'include',
            body: data,
          };
        },
        invalidatesTags: [{ type: 'Webhook', id: 'LIST' }],
      }),
    };
  },
});

export const {
  useCreateWebhookMutation,
  useUpdateWebhookMutation,
  useDeleteWebhookMutation,
  useGetWebhooksQuery,
  useGetWebhookQuery,
  useGetWebhookByWebhookKeyQuery,
} = webhookApi;
