import React from 'react';
import { ErrorBoundary } from 'components/error-boundary/error-boundary'; 
import { Layout } from '../layout';

type Props = {
  className?: string;
  children: React.ReactNode;
};

export const App = ({ className, children }: Props) => {
  return (
    <div className={className}>
      <ErrorBoundary>
        <Layout>{children}</Layout>
      </ErrorBoundary>
    </div>
  );
};
