import { useState } from 'react';

export default function useApiKey() {
  const getApiKey = () => {
    let apiKeyString = localStorage.getItem('apiKey');

    let apiKey = JSON.parse(apiKeyString);

    return apiKey;
  };

  const [apiKey, setApiKey] = useState(getApiKey());

  const saveApiKey = (apiKey: string) => {
    localStorage.setItem('apiKey', JSON.stringify(apiKey));
    setApiKey(apiKey);
  };

  return {
    setApiKey: saveApiKey,
    getApiKey,
    apiKey,
  };
}
