import { Webhook, WebhookEvent } from 'config/types';
import { appApi } from './app-api-slice';

export const webhookEventApi = appApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getWebhookEvents: builder.query<WebhookEvent[], Webhook>({
        query: ({ clientKey, webhookKey }) => ({
          url: `/webhook_event?clientKey=${clientKey}&webhookKey=${webhookKey}`,
          credential: 'include',
          method: 'GET',
        }),
        providesTags: [{ type: 'WebhookEvent', id: 'LIST' }],
      }),
    };
  },
});

export const { useGetWebhookEventsQuery } = webhookEventApi;
