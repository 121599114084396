import { SendAgreement } from 'config/types';
import { appApi } from './app-api-slice';

export const sendAgreementApi = appApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      sendAgreement: builder.mutation<SendAgreement, Partial<SendAgreement>>({
        query: (data) => ({
          url: `/send_agreement`,
          method: 'POST',
          credential: 'include',
          body: data,
        }),
      }),
    };
  },
});

export const { useSendAgreementMutation } = sendAgreementApi;
