import { ContractShareLink } from 'config/types';
import { appApi } from './app-api-slice';

export const contractShareLinkApi = appApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getShareLinks: builder.query<
        ContractShareLink[],
        Partial<ContractShareLink>
      >({
        query: ({ controlLink, userId }) => ({
          url: `/contract_share_link?controlLink=${controlLink}`,
          method: 'GET',
          credential: 'include',
        }),
        providesTags: [{ type: 'ContractShareLink', id: 'LIST' }],
      }),
      getShareLink: builder.query<ContractShareLink, string>({
        query: (controlLink) => ({
          url: `/contract_share_link?controlLink=${controlLink}`,
          method: 'GET',
          credential: 'include',
        }),
        providesTags: [{ type: 'ContractShareLink', id: 'LIST' }],
      }),
      createShareLink: builder.mutation<
        ContractShareLink,
        Partial<ContractShareLink>
      >({
        query: (body) => ({
          url: '/contract_share_link',
          method: 'POST',
          credential: 'include',
          body,
        }),
        invalidatesTags: [{ type: 'ContractShareLink', id: 'LIST' }],
      }),
      updateShareLink: builder.mutation<
        void,
        Pick<ContractShareLink, 'controlLink'> & Partial<ContractShareLink>
      >({
        query: ({ controlLink, ...patch }) => ({
          url: `/contract_share_link?controlLink=${controlLink}`,
          method: 'PUT',
          credential: 'include',
          body: patch,
        }),
        async onQueryStarted(
          { controlLink, ...patch },
          { dispatch, queryFulfilled }
        ) {
          const patchResult = dispatch(
            contractShareLinkApi.util.updateQueryData(
              'getShareLink',
              'controlLink',
              (draft: any) => {
                Object.assign(draft, patch);
              }
            )
          );
          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();
          }
        },
        invalidatesTags: [{ type: 'ContractShareLink', id: 'LIST' }],
      }),
      deleteShareLink: builder.mutation<
        { success: boolean; data: ContractShareLink },
        ContractShareLink
      >({
        query(data) {
          return {
            url: `/contract_share_link?id=${data.id}`,
            method: 'DELETE',
            credential: 'include',
            body: data,
          };
        },
        invalidatesTags: [{ type: 'ContractShareLink', id: 'LIST' }],
      }),
    };
  },
});

export const {
  useCreateShareLinkMutation,
  useDeleteShareLinkMutation,
  useUpdateShareLinkMutation,
  useGetShareLinkQuery,
  useGetShareLinksQuery,
} = contractShareLinkApi;
