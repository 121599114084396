import React from 'react';
import { useSelector } from 'react-redux';
import { contractSelector } from 'slices/app-slice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faLock } from '@fortawesome/free-solid-svg-icons';
import { Space, Card, Typography, Button } from 'antd';
import { Contract, TimelineItems } from 'config/types';
import { useUpdateContractMutation } from 'slices/contract-api-slice';
import { useAuth } from '@clerk/clerk-react';
import { useHistory } from 'react-router-dom';
type Props = {
  stage: TimelineItems;
};
export const UpdateControlLinkBlock = ({ stage }: Props) => {
  const history = useHistory();
  const contract = useSelector(contractSelector);
  const { Title, Text } = Typography;
  const { userId } = useAuth();
  const [updateControlLink] = useUpdateContractMutation();

  // console.log('ControlLinkBlock');
  const handleSaveLink = async () => {
    await updateControlLink({
      controlLink: contract.controlLink,
      userId: userId,
    })
      .unwrap()
      .then((payload) => {
        const data: Contract = payload as unknown as Contract;
        if (data) {
          history.push(`/user-control/${data.controlLink}#part-3`);
        }
      });
  };
  return (
    <Card
      id="part-5"
      bordered={true}
      style={{ maxWidth: '1200px', opacity: stage.opacity }}
    >
      <Space direction="vertical" size={16} style={{ display: 'flex' }}>
        <Space direction="vertical" size={2} style={{ maxWidth: '600px' }}>
          <Title level={4} style={{ margin: '0 0 0 0' }}>
            <FontAwesomeIcon icon={faLock} style={{ marginRight: '8px' }} />
            Your can save your contract in your workspace
          </Title>

          {/* <Text type="secondary">
            This document is in a secure space, and it's only accessible through
            a control link that only you own. Please copy and save the link
            below.
          </Text> */}
        </Space>
        <Button
          type="primary"
          icon={<FontAwesomeIcon icon={faLink} />}
          onClick={handleSaveLink}
        >
          Save contract at workspace
        </Button>
      </Space>
    </Card>
  );
};
