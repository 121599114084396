import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApplicationState } from 'store';
import {
  TimelineItems,
  Contract,
  ContractSign,
  ContractValue,
  ContractModal,
  Client,
  Webhook,
  Placeholder,
} from 'config/types';
import {
  ContractLink,
  ShareLinkView,
  StatusColor,
  StatusOpacity,
  TimelineBlock,
} from 'config/enums';

export interface AppState {
  loading: boolean;
  view: number;
  contractLink: ContractLink;
  timelineItems: TimelineItems[];
  timelineItemsModal: TimelineItems[];
  contract: Contract;
  contractValue: ContractValue;
  contractSign: ContractSign;
  pdfFile: ArrayBuffer | null;
  pdfFileLoad: number;
  // pdfFileUpload: Blob | null;
  createContract: boolean;
  shareBlockReady: boolean;
  controlBlockReady: boolean;
  timelineReady: boolean;
  timelineItemsBlock: TimelineBlock;
  signModal: boolean;
  approveModal: boolean;
  resultModal: ContractModal;
  webhookModal: boolean;
  payloadModal: boolean;
  sign: any;
  templateText: string;
  text: string;
  notification: { text: string | React.ReactNode };
  signDisable: boolean;
  approveDisable: boolean;
  addBtnVisible: boolean;
  fileName: string;
  currentClient: Client;
  clients: Client[];
  chooseWebhook: Webhook | undefined;
  currentApiKey: string;
  currentSecret: string;
  isDone: boolean;
  ipInfo: string;
  fillPlaceholderBefore: boolean;
  placeholders: Placeholder[];
  placeholdersFilling: boolean;
  activePlan: { active: boolean; quantity: number };
}

const initialState: AppState = {
  loading: false,
  view: 0,
  contractLink: ContractLink.CREATE,
  timelineItems: [
    {
      color: StatusColor.ACTIVE,
      opacity: StatusOpacity.ACTIVE,
      disable: false,
    },
    { color: StatusColor.WAIT, opacity: StatusOpacity.WAIT, disable: true },
    { color: StatusColor.WAIT, opacity: StatusOpacity.WAIT, disable: true },
  ],
  timelineItemsModal: [
    {
      color: StatusColor.ACTIVE,
      opacity: StatusOpacity.ACTIVE,
      disable: false,
    },
    {
      color: StatusColor.WAIT,
      opacity: StatusOpacity.WAIT,
      disable: true,
    },
  ],
  contract: { contractValue: '', view: ShareLinkView.VIEW },
  contractValue: {},
  contractSign: {},
  pdfFileLoad: 0,
  createContract: false,
  shareBlockReady: false,
  controlBlockReady: false,
  timelineReady: false,
  timelineItemsBlock: TimelineBlock.CONTRACT_TYPE_BLOCK,
  signModal: false,
  approveModal: false,
  resultModal: { open: false, action: undefined },
  webhookModal: false,
  payloadModal: false,
  sign: null,
  templateText: '',
  text: '',
  notification: { text: '' },
  signDisable: false,
  approveDisable: false,
  addBtnVisible: true,
  pdfFile: null,
  // pdfFileUpload: null,
  fileName: '',
  currentClient: {},
  clients: [],
  chooseWebhook: {},
  currentApiKey: '',
  currentSecret: '',
  isDone: false,
  ipInfo: '',
  fillPlaceholderBefore: false,
  placeholders: [],
  placeholdersFilling: false,
  activePlan: { active: false, quantity: 0 },
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setSignModal: (state, action: PayloadAction<boolean>) => {
      state.signModal = action.payload;
    },
    setApproveModal: (state, action: PayloadAction<boolean>) => {
      state.approveModal = action.payload;
    },
    setResultModal: (state, action: PayloadAction<ContractModal>) => {
      state.resultModal = action.payload;
    },
    setWebhookModal: (state, action: PayloadAction<boolean>) => {
      state.webhookModal = action.payload;
    },
    setPayloadModal: (state, action: PayloadAction<boolean>) => {
      state.payloadModal = action.payload;
    },
    setSignDisable: (state, action: PayloadAction<boolean>) => {
      state.signDisable = action.payload;
    },
    setApproveDisable: (state, action: PayloadAction<boolean>) => {
      state.approveDisable = action.payload;
    },
    setAddBtnVisible: (state, action: PayloadAction<boolean>) => {
      state.addBtnVisible = action.payload;
    },
    setPdfFileLoad: (state, action: PayloadAction<number>) => {
      state.pdfFileLoad = action.payload;
    },
    setView: (state, action: PayloadAction<number>) => {
      state.view = action.payload;
    },
    setTimelineItems: (state, action: PayloadAction<TimelineItems[]>) => {
      state.timelineItems = action.payload;
    },
    setTimelineItemsModal: (state, action: PayloadAction<TimelineItems[]>) => {
      state.timelineItemsModal = action.payload;
    },
    setContractLink: (state, action: PayloadAction<ContractLink>) => {
      state.contractLink = action.payload;
    },
    setContractSign: (state, action: PayloadAction<ContractSign>) => {
      state.contractSign = action.payload;
    },
    setContractValue: (state, action: PayloadAction<ContractValue>) => {
      state.contractValue = action.payload;
    },
    setContract: (state, action: PayloadAction<Contract>) => {
      state.contract = action.payload;
    },
    setCreateContract: (state, action: PayloadAction<boolean>) => {
      state.createContract = action.payload;
    },
    setShareLinkView: (state, action: PayloadAction<ShareLinkView>) => {
      state.contract.view = action.payload;
    },
    setShareBlockReady: (state, action: PayloadAction<boolean>) => {
      state.shareBlockReady = action.payload;
    },
    setControlBlockReady: (state, action: PayloadAction<boolean>) => {
      state.controlBlockReady = action.payload;
    },
    setTimelineReady: (state, action: PayloadAction<boolean>) => {
      state.timelineReady = action.payload;
    },
    setTemplateText: (state, action: PayloadAction<string>) => {
      state.templateText = action.payload;
    },
    setTextState: (state, action: PayloadAction<string>) => {
      state.text = action.payload;
    },
    setFileName: (state, action: PayloadAction<string>) => {
      state.fileName = action.payload;
    },
    setCurrentApiKey: (state, action: PayloadAction<string>) => {
      state.currentApiKey = action.payload;
    },
    setCurrentSecret: (state, action: PayloadAction<string>) => {
      state.currentSecret = action.payload;
    },
    setTimelineItemsBlock: (state, action: PayloadAction<TimelineBlock>) => {
      state.timelineItemsBlock = action.payload;
    },
    setSign: (state, action: PayloadAction<any>) => {
      state.sign = action.payload;
    },
    setPdfFile: (state, action: PayloadAction<ArrayBuffer>) => {
      state.pdfFile = action.payload;
    },
    setClients: (state, action: PayloadAction<Client[]>) => {
      state.clients = action.payload;
    },
    setCurrentClient: (state, action: PayloadAction<Client>) => {
      state.currentClient = action.payload;
    },
    setChooseWebhook: (state, action: PayloadAction<Webhook>) => {
      state.chooseWebhook = action.payload;
    },
    setIpInfo: (state, action: PayloadAction<string>) => {
      state.ipInfo = action.payload;
    },
    // setPdfFileUpload: (state, action: PayloadAction<Blob>) => {
    //   state.pdfFileUpload = action.payload;
    // },
    setIsDone: (state, action: PayloadAction<boolean>) => {
      state.isDone = action.payload;
    },
    setFillPlaceholderBefore: (state, action: PayloadAction<boolean>) => {
      state.fillPlaceholderBefore = action.payload;
    },
    setPlaceholdersFilling: (state, action: PayloadAction<boolean>) => {
      state.placeholdersFilling = action.payload;
    },
    setActivePlan: (
      state,
      action: PayloadAction<{ active: boolean; quantity: number }>
    ) => {
      state.activePlan = action.payload;
    },
    setPlaceholders: (state, action: PayloadAction<Placeholder[]>) => {
      state.placeholders = action.payload;
    },
    setNotification: (
      state,
      action: PayloadAction<{
        text: string | React.ReactNode;
      }>
    ) => {
      state.notification = action.payload;
    },
    resetAppState: () => initialState,
  },
});

export const {
  setAppLoading,
  setView,
  setTimelineItems,
  setTimelineItemsModal,
  setContract,
  setContractSign,
  setContractLink,
  setCreateContract,
  setShareLinkView,
  setShareBlockReady,
  setControlBlockReady,
  setTimelineReady,
  setPdfFileLoad,
  setTimelineItemsBlock,
  setContractValue,
  setSignModal,
  setApproveModal,
  setResultModal,
  setWebhookModal,
  setPayloadModal,
  setSignDisable,
  setApproveDisable,
  setAddBtnVisible,
  setSign,
  setCurrentApiKey,
  setCurrentSecret,
  setTemplateText,
  setTextState,
  setNotification,
  setPdfFile,
  setClients,
  setCurrentClient,
  setChooseWebhook,
  // setPdfFileUpload,
  setFileName,
  setIsDone,
  setIpInfo,
  setFillPlaceholderBefore,
  setPlaceholdersFilling,
  setActivePlan,
  setPlaceholders,
  resetAppState,
} = appSlice.actions;

export const loadingSelector = (state: ApplicationState) => state.app.loading;
export const signModalSelector = (state: ApplicationState) =>
  state.app.signModal;
export const approveModalSelector = (state: ApplicationState) =>
  state.app.approveModal;
export const resultModalSelector = (state: ApplicationState) =>
  state.app.resultModal;
export const webhookModalSelector = (state: ApplicationState) =>
  state.app.webhookModal;
export const payloadModalSelector = (state: ApplicationState) =>
  state.app.payloadModal;
export const viewSelector = (state: ApplicationState) => state.app.view;
export const timelineItemsSelector = (state: ApplicationState) =>
  state.app.timelineItems;
export const timelineItemsModalSelector = (state: ApplicationState) =>
  state.app.timelineItemsModal;
export const contractLinkSelector = (state: ApplicationState) =>
  state.app.contractLink;
export const contractSignSelector = (state: ApplicationState) =>
  state.app.contractSign;
export const contractSelector = (state: ApplicationState) => state.app.contract;
export const createContractSelector = (state: ApplicationState) =>
  state.app.createContract;
export const contractValueSelector = (state: ApplicationState) =>
  state.app.contractValue;
export const timelineItemsBlockSelector = (state: ApplicationState) =>
  state.app.timelineItemsBlock;
export const signSelector = (state: ApplicationState) => state.app.sign;
export const templateTextSelector = (state: ApplicationState) =>
  state.app.templateText;
export const textSelector = (state: ApplicationState) => state.app.text;
export const notificationSelector = (state: ApplicationState) =>
  state.app.notification;
export const signDisableSelector = (state: ApplicationState) =>
  state.app.signDisable;
export const approveDisableSelector = (state: ApplicationState) =>
  state.app.approveDisable;
export const addBtnVisibleSelector = (state: ApplicationState) =>
  state.app.addBtnVisible;
export const shareLinkViewSelector = (state: ApplicationState) =>
  state.app.contract.view;
export const shareBlockReadySelector = (state: ApplicationState) =>
  state.app.shareBlockReady;
export const controlBlockReadySelector = (state: ApplicationState) =>
  state.app.controlBlockReady;
export const timelineReadySelector = (state: ApplicationState) =>
  state.app.timelineReady;
export const pdfFileSelector = (state: ApplicationState) => state.app.pdfFile;
// export const pdfFileUploadSelector = (state: ApplicationState) =>
//   state.app.pdfFileUpload;
export const fileNameSelector = (state: ApplicationState) => state.app.fileName;
export const pdfFileLoadSelector = (state: ApplicationState) =>
  state.app.pdfFileLoad;
export const clientsSelector = (state: ApplicationState) => state.app.clients;
export const currentClientSelector = (state: ApplicationState) =>
  state.app.currentClient;
export const chooseWebhookSelector = (state: ApplicationState) =>
  state.app.chooseWebhook;
export const apiKeySelector = (state: ApplicationState) =>
  state.app.currentApiKey;
export const secretSelector = (state: ApplicationState) =>
  state.app.currentSecret;
export const isDoneSelector = (state: ApplicationState) => state.app.isDone;
export const ipInfoSelector = (state: ApplicationState) => state.app.ipInfo;
export const fillPlaceholderBeforeSelector = (state: ApplicationState) =>
  state.app.fillPlaceholderBefore;
export const placeholdersFillingSelector = (state: ApplicationState) =>
  state.app.placeholdersFilling;
export const placeholdersSelector = (state: ApplicationState) =>
  state.app.placeholders;
export const activePlanSelector = (state: ApplicationState) =>
  state.app.activePlan;
