import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  contractSelector,
  setContract,
  setNotification,
  viewSelector,
} from 'slices/app-slice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCopy,
  faLink,
  faLock,
  faUser,
} from '@fortawesome/free-solid-svg-icons';
import { Space, Card, Typography, Button, Input, Tooltip, Divider } from 'antd';
import { TimelineItems } from 'config/types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useCreateContractMutation } from 'slices/contract-api-slice';
import { SignUpButton } from '@clerk/clerk-react';
type Props = {
  stage: TimelineItems;
};
export const ControlLinkBlock = ({ stage }: Props) => {
  const dispatch = useDispatch();
  const view = useSelector(viewSelector);
  const contract = useSelector(contractSelector);
  const [copyVisible, setCopyVisible] = useState(false);
  const [controlLink, setControlLink] = useState('');
  const { Title, Text } = Typography;
  const [createContractLink] = useCreateContractMutation();

  // console.log('ControlLinkBlock');
  const handleCreateLink = async () => {
    if (!contract || (contract && !contract.controlLink)) {
      await createContractLink({ contractType: view + 1 })
        .unwrap()
        .then((payload) => {
          dispatch(
            setContract({
              contractType: view + 1,
              controlLink: payload.controlLink,
              shareLink: payload.shareLink,
            })
          );
          setControlLink(
            `${window.location.origin}/control/${payload.controlLink}`
          );
          setCopyVisible(true);
        });
    } else {
      setControlLink(
        `${window.location.origin}/control/${contract.controlLink}`
      );
      setCopyVisible(true);
    }
  };
  const handleClick = () => {
    dispatch(
      setNotification({
        text: `Control link copied. Do not share the link with recipients.`,
      })
    );
  };
  return (
    <Card
      id="part-4"
      bordered={true}
      style={{ maxWidth: '1200px', opacity: stage.opacity }}
    >
      <Space direction="vertical" size={16} style={{ display: 'flex' }}>
        <Space direction="vertical" size={2} style={{ maxWidth: '600px' }}>
          <Title level={4} style={{ margin: '0 0 0 0' }}>
            <FontAwesomeIcon icon={faLock} style={{ marginRight: '8px' }} />
            Your document is protected
          </Title>

          <Text type="secondary">
            This document is in a secure space, and it's only accessible through
            a control link that only you own. Please copy and save the link
            below.
          </Text>
        </Space>
        {!copyVisible && (
          <Button
            type="primary"
            icon={<FontAwesomeIcon icon={faLink} />}
            onClick={handleCreateLink}
          >
            Get control link
          </Button>
        )}
        {copyVisible && (
          <>
            <Input
              id="controlLink"
              readOnly={true}
              placeholder="The control link"
              value={controlLink}
              suffix={
                <CopyToClipboard
                  text={controlLink}
                  children={
                    <Tooltip title="Copy to clipboard.">
                      <Button
                        id="buttonControlLink1"
                        type="text"
                        size="small"
                        icon={<FontAwesomeIcon icon={faCopy} />}
                        onClick={handleClick}
                      />
                    </Tooltip>
                  }
                />
              }
            />
            <CopyToClipboard
              text={controlLink}
              children={
                <Tooltip title="Save this link, so you can always get back to this contract as an owner.">
                  <Button
                    id="buttonControlLink2"
                    icon={<FontAwesomeIcon icon={faLink} />}
                    onClick={handleClick}
                  >
                    Copy control link
                  </Button>
                </Tooltip>
              }
            />
            <Divider />

            <Text type="secondary">
              Need even more control? Sign in and save this document to your
              account.
            </Text>
            <SignUpButton mode="modal">
              <Button
                type="primary"
                icon={<FontAwesomeIcon icon={faUser} />}
                onClick={handleCreateLink}
              >
                Sign up
              </Button>
            </SignUpButton>
          </>
        )}
      </Space>
    </Card>
  );
};
