import React from 'react';
import { ContractLink, Stages, TimelineBlock } from 'config/enums';
import { TimelineItems } from 'config/types';
import { ChooseContractBlock } from 'components/choose-contract-block/choose-contract-block';
import { EditorBlock } from 'components/editor-block/editor-block';
import { ShareLinkBlock } from 'components/share-link-block/share-link-block';
import { ControlLinkBlock } from 'components/control-link-block/control-link-block';
import { TimelineItemProps } from 'antd';
import { InitialEditorBlock } from 'components/initial-editor-block/initial-editor-block';
import { DocumentTimilineBlock } from 'components/document-timeline-block/document-timeline-block';
import { PdfBlock } from 'components/pdf-block/pdf-block';
import { UpdateControlLinkBlock } from 'components/update-control-link-block/update-control-link-block';
type Props = {
  type:
    | ''
    | ContractLink.CONTROL
    | ContractLink.SHARE
    | ContractLink.USER_CONTROL;
  block: TimelineBlock;
  timelineItems: TimelineItems[];
  userId?: boolean;
};
export const timelineItemsBlock = ({
  type,
  block,
  timelineItems,
  userId = false,
}: Props): TimelineItemProps[] => {
  // debugger;
  // console.log('timelineItemsBlock', type, block);
  switch (type) {
    case '':
      switch (block) {
        case TimelineBlock.CONTRACT_TYPE_BLOCK:
          return [
            {
              color: timelineItems[Stages.CHOOSE_TYPE].color,
              children: (
                <ChooseContractBlock
                  stage={timelineItems[Stages.CHOOSE_TYPE]}
                />
              ),
            },
            {
              color: timelineItems[Stages.EDIT_CONTRACT].color,
              children: (
                <InitialEditorBlock
                  stage={timelineItems[Stages.EDIT_CONTRACT]}
                />
              ),
            },
          ];
        case TimelineBlock.CHOOSE_TYPE_BLOCK:
          return [
            {
              color: timelineItems[Stages.CHOOSE_TYPE].color,
              children: (
                <ChooseContractBlock
                  stage={timelineItems[Stages.CHOOSE_TYPE]}
                />
              ),
            },
            {
              color: timelineItems[Stages.EDIT_CONTRACT].color,
              children: (
                <InitialEditorBlock
                  stage={timelineItems[Stages.EDIT_CONTRACT]}
                />
              ),
            },
          ];
        case TimelineBlock.PDF_BLOCK:
          return [
            {
              color: timelineItems[Stages.CHOOSE_TYPE].color,
              children: (
                <ChooseContractBlock
                  stage={timelineItems[Stages.CHOOSE_TYPE]}
                />
              ),
            },
            {
              color: timelineItems[Stages.EDIT_CONTRACT].color,
              children: <PdfBlock />,
            },
            {
              color: timelineItems[Stages.CREATE_LINK].color,
              children: (
                <ShareLinkBlock stage={timelineItems[Stages.CREATE_LINK]} />
              ),
            },
          ];
        case TimelineBlock.EDITOR_BLOCK:
          return [
            {
              color: timelineItems[Stages.CHOOSE_TYPE].color,
              children: (
                <ChooseContractBlock
                  stage={timelineItems[Stages.CHOOSE_TYPE]}
                />
              ),
            },
            {
              color: timelineItems[Stages.EDIT_CONTRACT].color,
              children: (
                <InitialEditorBlock
                  stage={timelineItems[Stages.EDIT_CONTRACT]}
                />
              ),
            },
            {
              color: timelineItems[Stages.CREATE_LINK].color,
              children: (
                <ShareLinkBlock stage={timelineItems[Stages.CREATE_LINK]} />
              ),
            },
          ];
        case TimelineBlock.SHARE_LINK_BLOCK:
          return [
            {
              color: timelineItems[Stages.EDIT_CONTRACT].color,
              children: (
                <DocumentTimilineBlock
                  stage={timelineItems[Stages.CHOOSE_TYPE]}
                />
              ),
            },
            {
              color: timelineItems[Stages.EDIT_CONTRACT].color,
              children: (
                <EditorBlock stage={timelineItems[Stages.EDIT_CONTRACT]} />
              ),
            },
            {
              color: timelineItems[Stages.CREATE_LINK].color,
              children: (
                <ShareLinkBlock stage={timelineItems[Stages.CREATE_LINK]} />
              ),
            },
            {
              color: timelineItems[Stages.CREATE_LINK].color,
              children: (
                <ControlLinkBlock stage={timelineItems[Stages.CREATE_LINK]} />
              ),
            },
          ];
        case TimelineBlock.SHARE_LINK_PDF_BLOCK:
          return [
            {
              color: timelineItems[Stages.EDIT_CONTRACT].color,
              children: (
                <DocumentTimilineBlock
                  stage={timelineItems[Stages.CHOOSE_TYPE]}
                />
              ),
            },
            {
              color: timelineItems[Stages.EDIT_CONTRACT].color,
              children: <PdfBlock />,
            },
            {
              color: timelineItems[Stages.CREATE_LINK].color,
              children: (
                <ShareLinkBlock stage={timelineItems[Stages.CREATE_LINK]} />
              ),
            },
            {
              color: timelineItems[Stages.CREATE_LINK].color,
              children: (
                <ControlLinkBlock stage={timelineItems[Stages.CREATE_LINK]} />
              ),
            },
          ];
        default:
          return [];
      }
    case ContractLink.CONTROL:
      switch (block) {
        case TimelineBlock.SHARE_LINK_BLOCK:
        case TimelineBlock.CONTRACT_TYPE_BLOCK:
          const array: TimelineItemProps[] = [];
          array.push(
            {
              color: timelineItems[Stages.EDIT_CONTRACT].color,
              children: (
                <DocumentTimilineBlock
                  stage={timelineItems[Stages.CHOOSE_TYPE]}
                />
              ),
            },
            {
              color: timelineItems[Stages.EDIT_CONTRACT].color,
              children: (
                <EditorBlock stage={timelineItems[Stages.EDIT_CONTRACT]} />
              ),
            },
            {
              color: timelineItems[Stages.CREATE_LINK].color,
              children: (
                <ShareLinkBlock stage={timelineItems[Stages.CREATE_LINK]} />
              ),
            },
            {
              color: timelineItems[Stages.CREATE_LINK].color,
              children: (
                <ControlLinkBlock stage={timelineItems[Stages.CREATE_LINK]} />
              ),
            }
          );
          if (userId) {
            array.push({
              color: timelineItems[Stages.CREATE_LINK].color,
              children: (
                <UpdateControlLinkBlock
                  stage={timelineItems[Stages.CREATE_LINK]}
                />
              ),
            });
          }
          return array;
        case TimelineBlock.SHARE_LINK_PDF_BLOCK:
          return [
            {
              color: timelineItems[Stages.EDIT_CONTRACT].color,
              children: (
                <DocumentTimilineBlock
                  stage={timelineItems[Stages.CHOOSE_TYPE]}
                />
              ),
            },
            {
              color: timelineItems[Stages.EDIT_CONTRACT].color,
              children: <PdfBlock />,
            },
            {
              color: timelineItems[Stages.CREATE_LINK].color,
              children: (
                <ShareLinkBlock stage={timelineItems[Stages.CREATE_LINK]} />
              ),
            },
            {
              color: timelineItems[Stages.CREATE_LINK].color,
              children: (
                <ControlLinkBlock stage={timelineItems[Stages.CREATE_LINK]} />
              ),
            },
          ];

        default:
          return [];
      }
    case ContractLink.USER_CONTROL:
      switch (block) {
        case TimelineBlock.SHARE_LINK_BLOCK:
        case TimelineBlock.CONTRACT_TYPE_BLOCK:
          return [
            {
              color: timelineItems[Stages.EDIT_CONTRACT].color,
              children: (
                <DocumentTimilineBlock
                  stage={timelineItems[Stages.CHOOSE_TYPE]}
                />
              ),
            },
            {
              color: timelineItems[Stages.EDIT_CONTRACT].color,
              children: (
                <EditorBlock stage={timelineItems[Stages.EDIT_CONTRACT]} />
              ),
            },
            {
              color: timelineItems[Stages.CREATE_LINK].color,
              children: (
                <ShareLinkBlock stage={timelineItems[Stages.CREATE_LINK]} />
              ),
            },
            {
              color: timelineItems[Stages.CREATE_LINK].color,
              children: (
                <ControlLinkBlock stage={timelineItems[Stages.CREATE_LINK]} />
              ),
            },
          ];
        case TimelineBlock.SHARE_LINK_PDF_BLOCK:
          return [
            {
              color: timelineItems[Stages.EDIT_CONTRACT].color,
              children: (
                <DocumentTimilineBlock
                  stage={timelineItems[Stages.CHOOSE_TYPE]}
                />
              ),
            },
            {
              color: timelineItems[Stages.EDIT_CONTRACT].color,
              children: <PdfBlock />,
            },
            {
              color: timelineItems[Stages.CREATE_LINK].color,
              children: (
                <ShareLinkBlock stage={timelineItems[Stages.CREATE_LINK]} />
              ),
            },
            {
              color: timelineItems[Stages.CREATE_LINK].color,
              children: (
                <ControlLinkBlock stage={timelineItems[Stages.CREATE_LINK]} />
              ),
            },
          ];

        default:
          return [];
      }

    case ContractLink.SHARE:
      break;
  }
};
