/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';

import './portal-page.module.css';
import {
  Space,
  Typography,
  Button,
  Timeline,
  Card,
  Tooltip,
  Divider,
  Input,
  Switch,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
const { Title, Text } = Typography;
const { TextArea } = Input;

export const PortalPage: React.FC = () => {
  return (
    <Space direction="vertical" style={{ display: 'flex' }}>
      <Space direction="vertical" size={4} style={{ margin: '0 0 32px 0' }}>
        <Title level={3} style={{ margin: '0 0 0 0' }}>
          Grant access to your templates to anyone
        </Title>
        <Text type="secondary">
          Follow these steps to prepare your public portal.
        </Text>
      </Space>
      <Timeline
        items={[
          {
            color: 'gray',
            children: (
              <>
                <Card style={{ opacity: 1 }} bordered={true}>
                  <Space
                    direction="vertical"
                    size={16}
                    style={{ display: 'flex' }}
                  >
                    <Space direction="vertical" size={2}>
                      <Title level={4} style={{ margin: '0 0 0 0' }}>
                        Choose the templates that should appear on your portal
                      </Title>
                      <Text type="secondary">
                        Control their availability and add descriptions.
                      </Text>
                    </Space>
                    <Space wrap size={16} align="start">
                      <Card
                        title={
                          <Tooltip title="YC SAFE Valuation Cap No Discount">
                            <Text>YC SAFE Valuation Cap No Discount</Text>
                          </Tooltip>
                        }
                        extra={
                          <Tooltip title="Switch availability on the portal">
                            <Switch style={{ marginLeft: 16 }} defaultChecked />
                          </Tooltip>
                        }
                        style={{ width: 300 }}
                        type="inner"
                      >
                        <TextArea
                          autoSize={{ minRows: 2, maxRows: 6 }}
                          placeholder="Add description"
                          maxLength={200}
                        />
                      </Card>
                      <Card
                        title={
                          <Tooltip title="Non-Disclosure Agreement">
                            <Text>Non-Disclosure Agreement</Text>
                          </Tooltip>
                        }
                        extra={
                          <Tooltip title="Switch availability on the portal">
                            <Switch style={{ marginLeft: 16 }} defaultChecked />
                          </Tooltip>
                        }
                        style={{ width: 300 }}
                        type="inner"
                      >
                        <TextArea
                          autoSize={{ minRows: 2, maxRows: 6 }}
                          placeholder="Add description"
                          maxLength={200}
                        />
                      </Card>
                      <Card
                        title={
                          <Tooltip title="Sales agreement">
                            <Text>Sales agreement</Text>
                          </Tooltip>
                        }
                        extra={
                          <Tooltip title="Switch availability on the portal">
                            <Switch style={{ marginLeft: 16 }} defaultChecked />
                          </Tooltip>
                        }
                        style={{ width: 300 }}
                        type="inner"
                      >
                        <TextArea
                          autoSize={{ minRows: 2, maxRows: 6 }}
                          placeholder="Add description"
                          maxLength={200}
                        />
                      </Card>
                    </Space>
                  </Space>
                </Card>
              </>
            ),
          },

          {
            color: 'gray',
            children: (
              <>
                <Card bordered={true}>
                  <Space
                    direction="vertical"
                    size={16}
                    style={{ display: 'flex' }}
                  >
                    <Space direction="vertical" size={2}>
                      <Title level={4} style={{ margin: '0 0 0 0' }}>
                        Share your portal
                      </Title>
                      <Text type="secondary">
                        Any document created via the portal will be saved in
                        your workspace.
                      </Text>
                    </Space>
                    <Text type="secondary">Share link to the portal:</Text>
                    <Input
                      readOnly
                      value="https://sendfrosign.com/portal/appJBe9tplOLcl57l/shr6CnypleHtO2JtO"
                      placeholder="The contract link"
                    />
                    <Button
                      type="text"
                      icon={<FontAwesomeIcon icon={faCopy} />}
                    >
                      Copy link
                    </Button>
                    <Divider plain>or</Divider>

                    <Text type="secondary">Embed the portal:</Text>
                    <TextArea
                      autoSize
                      placeholder="Add description"
                      readOnly
                      value='<iframe class="sfs-embed" src="https://sendfrosign.com/embed/appJBe9tplOLcl57l/shr6CnypleHtO2JtO?backgroundColor=green" frameborder="0" onmousewheel="" width="100%" style="background: transparent; border: 1px solid #ccc;"></iframe>'
                    />
                    <Button
                      type="text"
                      icon={<FontAwesomeIcon icon={faCopy} />}
                    >
                      Copy embed
                    </Button>
                  </Space>
                </Card>
              </>
            ),
          },
        ]}
      />
    </Space>
  );
};
