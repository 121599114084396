import { Token } from 'config/types';
import { appApi } from './app-api-slice';

export const tokenApi = appApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      generateToken: builder.mutation<Token, Partial<Token>>({
        query: (data) => ({
          url: `/token`,
          method: 'POST',
          credential: 'include',
          body: data,
        }),
        invalidatesTags: [{ type: 'Token', id: 'LIST' }],
      }),
    };
  },
});

export const { useGenerateTokenMutation } = tokenApi;
