import { ContractType, TemplateText } from 'config/types';
import { appApi } from './app-api-slice';

export const contractTypeApi = appApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getContractTypes: builder.query<ContractType[], void>({
        query: () => ({
          url: `/contract_type`,
          credential: 'include',
          method: 'GET',
        }),
        providesTags: [{ type: 'ContractType', id: 'LIST' }],
      }),
      getContractTypeTemplate: builder.mutation<
        TemplateText,
        Partial<TemplateText>
      >({
        query: (body) => ({
          url: `/template_text`,
          method: 'POST',
          credential: 'include',
          body: body,
        }),
      }),
    };
  },
});

export const { useGetContractTypesQuery, useGetContractTypeTemplateMutation } =
  contractTypeApi;
