import {
  ConstractSignBase64,
  ContractSign,
  ContractSignAi,
} from 'config/types';
import { appApi } from './app-api-slice';
import { readBase64 } from 'utils/api-helper';

export const contractSignApi = appApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      createContractSign: builder.mutation<ContractSign, Partial<ContractSign>>(
        {
          query: (body) => ({
            url: '/contract_sign',
            method: 'POST',
            credential: 'include',
            body,
          }),
          invalidatesTags: [
            { type: 'ContractSign', id: 'LIST' },
            { type: 'ContractEvent', id: 'LIST' },
          ],
        }
      ),
      getContractSign: builder.query<ContractSign, string>({
        query: (id) => ({
          url: `/contract_sign?id=${id}`,
          credential: 'include',
        }),
      }),
      getContractSignsByShareLink: builder.query<ContractSign[], string>({
        query: (shareLink) => ({
          url: `/contract_sign?shareLink=${shareLink}`,
          credential: 'include',
          method: 'GET',
        }),
        providesTags: [{ type: 'ContractSign', id: 'LIST' }],
      }),
      getContractSignsByControlLink: builder.query<
        ContractSign[],
        Partial<ContractSign>
      >({
        query: ({ controlLink }) => ({
          url: `/contract_sign?controlLink=${controlLink}`,
          credential: 'include',
          method: 'GET',
        }),
        providesTags: [{ type: 'ContractSign', id: 'LIST' }],
      }),
      getContractSignsAi: builder.mutation<
        ContractSignAi,
        Partial<ContractSignAi>
      >({
        query: (body) => ({
          url: `/contract_sign_ai`,
          credential: 'include',
          method: 'POST',
          body,
        }),
        invalidatesTags: [{ type: 'ContractSign', id: 'LIST' }],
      }),
      getContractSignAiBase64: builder.mutation<
        ConstractSignBase64,
        Partial<ConstractSignBase64>
      >({
        query: (body) => ({
          url: `/contract_sign_ai_base64`,
          credential: 'include',
          method: 'POST',
          body,
          responseHandler: async (response: any) => {
            return await readBase64(response);
          },
        }),
        // invalidatesTags: [{ type: 'ContractSign', id: 'LIST' }],
      }),
    };
  },
});

export const {
  useCreateContractSignMutation,
  // useUpdateContractSignMutation,
  useGetContractSignsByShareLinkQuery,
  useGetContractSignsByControlLinkQuery,
  useGetContractSignsAiMutation,
  useGetContractSignAiBase64Mutation,
} = contractSignApi;
