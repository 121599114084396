import React, { useEffect, useRef } from 'react';
import './initial-editor-block.module.css';
import * as Quillname from 'quill';
import QuillBetterTable from 'quill-better-table';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadingSelector,
  setTemplateText,
  setTextState,
  setTimelineItemsBlock,
  templateTextSelector,
} from 'slices/app-slice';
import { Space, Spin, Card, Typography } from 'antd';
import { TimelineItems } from 'config/types';
import { TimelineBlock } from 'config/enums';

const Quill: any = Quillname.default;
type Props = {
  stage: TimelineItems;
};
Quill.register(
  {
    'modules/better-table': QuillBetterTable,
  },
  true
);

export const InitialEditorBlock = ({ stage }: Props) => {
  const dispatch = useDispatch();
  const templateText = useSelector(templateTextSelector);
  const templateLoading = useSelector(loadingSelector);
  const { Title, Text } = Typography;
  const quillRef = useRef(null);

  useEffect(() => {
    quillRef.current = new Quill('#editor-container', {
      modules: {
        toolbar: {
          container: [
            ['bold', 'italic', 'underline', 'strike'], // toggled buttons
            ['blockquote'],

            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
            [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
            [{ direction: 'rtl' }], // text direction

            [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown

            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            [{ font: [] }],
            [{ align: [] }],

            ['link', 'image', 'table'],

            ['clean'],
          ],
          handlers: {
            table: addTable,
          },
        },
        table: false, // disable table module
        'better-table': {
          operationMenu: {
            items: {
              unmergeCells: {
                text: 'Unmerge',
              },
            },
          },
        },
        keyboard: {
          bindings: QuillBetterTable.keyboardBindings,
        },
        history: {
          delay: 5000,
          maxStack: 5000,
          userOnly: true,
        },
      },
      theme: 'bubble',
      scrollingContainer: 'body',  
    });

    quillRef.current
      .getModule('toolbar')
      .container.addEventListener('mousedown', (e) => {
        e.preventDefault();
        e.stopPropagation();
      });

    quillRef.current.on(
      'text-change',
      function (delta: any, oldDelta: any, source: any) {
        handleChange(quillRef.current.root.innerHTML);
      }
    );
  }, []);
  useEffect(() => {
    if (templateText && quillRef.current) {
      dispatch(setTimelineItemsBlock(TimelineBlock.EDITOR_BLOCK));
      quillRef.current.clipboard.dangerouslyPasteHTML(templateText);
      dispatch(setTemplateText(''));
    }
  }, [templateText, templateLoading]);
  const handleChange = (content: string) => {
    // debugger;
    // console.log('useDebouncedCallback', content);
    dispatch(setTextState(content));
  };
  const addTable = () => {
    // debugger;
    quillRef.current.getModule('better-table').insertTable(3, 3);
  };

  return (
    <Spin spinning={templateLoading}>
      <Card style={{ opacity: stage.opacity }} bordered={true}>
        <Space direction="vertical" size={16} style={{ display: 'flex' }}>
          <Space direction="vertical" size={2}>
            <Title level={4} style={{ margin: '0 0 0 0' }}>
              Review your document, highlight text to see options
            </Title>
            <Text type="secondary">
              The green text is where you may want to replace it with your own
              text.
            </Text>
          </Space>
          <div id="scroll-container">
            <div id="editor-container" />
          </div>
        </Space>
      </Card>
    </Spin>
  );
};
