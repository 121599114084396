import { UserInfo } from 'config/types';
import { appApi } from './app-api-slice';

export const userInfoApi = appApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      getUserInfo: builder.mutation<UserInfo, Partial<UserInfo>>({
        query: (body) => ({
          url: `/user_info`,
          method: 'POST',
          credential: 'include',
          body: body,
        }),
        invalidatesTags: [{ type: 'UserInfo', id: 'LIST' }],
      }),
    };
  },
});

export const { useGetUserInfoMutation } = userInfoApi;
