import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notificationSelector, setNotification } from 'slices/app-slice';
import { notification } from 'antd';

export const Notification = () => {
  const dispatch = useDispatch();
  const sendNotification = useSelector(notificationSelector);
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    if (sendNotification && sendNotification.text) {
      const key = `open${Date.now()}`;

      api.open({
        message: 'Sendforsign assistant',
        description: sendNotification.text,
        placement: 'bottomRight',
        // btn: btn,
        // onClose: closeNotification,
        key,
      });
      dispatch(setNotification({ text: '' }));
    }
  }, [sendNotification]);

  return <>{contextHolder}</>;
};
