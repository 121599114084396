import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { appApi } from 'slices/app-api-slice';

import { appSlice, AppState } from './slices/app-slice';

export type ApplicationState = {
  app: AppState;
  [appApi.reducerPath]: ReturnType<typeof appApi.reducer>;
};

const rootReducer = combineReducers<ApplicationState>({
  app: appSlice.reducer,
  [appApi.reducerPath]: appApi.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: true,
      immutableCheck: true,
    }).concat(appApi.middleware),
});
