import { Placeholder } from 'config/types';
import { appApi } from './app-api-slice';

export const placeholderApi = appApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      updatePlaceholders: builder.mutation<
        Placeholder[],
        Partial<Placeholder[]>
      >({
        query: (body) => ({
          url: `/placeholder?shareLink=${body[0]?.shareLink}`,
          method: 'POST',
          credential: 'include',
          body,
        }),
      }),
    };
  },
});

export const { useUpdatePlaceholdersMutation } = placeholderApi;
