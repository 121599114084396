import { MainPage } from './pages/main-page/main-page';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

export const Routes = () => (
  <Switch>
    <Route path="/">
      <MainPage />
    </Route>
  </Switch>
);
