import React, { useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { useDispatch, useSelector } from 'react-redux';
import {
  contractSelector,
  ipInfoSelector,
  setContractSign,
  setIsDone,
  setResultModal,
  setSign,
  setSignModal,
  setTimelineItemsModal,
  signModalSelector,
  timelineItemsModalSelector,
} from 'slices/app-slice';
import {
  Space,
  Spin,
  Card,
  Typography,
  Modal,
  Timeline,
  Button,
  Input,
  Tabs,
  TabsProps,
  Row,
  Carousel,
} from 'antd';
import styles from './sign-modal.module.css';
import { ContractAction, StagesModal } from 'config/enums';
import { nextStageModal } from 'utils/data-helper';
import {
  useCreateContractSignMutation,
  useGetContractSignAiBase64Mutation,
  useGetContractSignsAiMutation,
} from 'slices/contract-sign-api-slice';

export const SignModal = () => {
  const dispatch = useDispatch();
  const padRef = useRef(null);
  const fullNameRef = useRef('');
  const emailRef = useRef('');
  const signAiRef = useRef([]);
  const signModal = useSelector(signModalSelector);
  const timelineItems = useSelector(timelineItemsModalSelector);
  const contract = useSelector(contractSelector);
  const ipInfo = useSelector(ipInfoSelector);
  const [signDisable, setSignDisable] = useState(true);
  const [aiSignChoose, setAiSignChoose] = useState(0);
  const [clearBtnVisible, setClearBtnVisible] = useState(true);
  const [currentTab, setCurrentTab] = useState('1');
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');

  const [spinLoad, setSpinLoad] = useState(false);
  const [signLoad, setSignLoad] = useState(false);
  const { Title, Text } = Typography;
  // const { userId } = useAuth();

  const [saveSign] = useCreateContractSignMutation();
  const [generateAi] = useGetContractSignsAiMutation();
  const [getSignAiBase64] = useGetContractSignAiBase64Mutation();

  const handleBegin = () => {
    setSignDisable(false);
  };
  const handleAiClick = async () => {
    setSpinLoad(true);
    let itemsTmp: TabsProps['items'] = [];
    itemsTmp.push({
      key: '1',
      label: `Draw`,
      children: (
        <SignatureCanvas
          ref={padRef}
          penColor="rgb(34, 30, 252)"
          canvasProps={{ width: 350, height: 200, className: 'sigCanvas' }}
          onBegin={handleBegin}
        />
      ),
    });
    itemsTmp.push({
      key: '2',
      label: `Generate with AI`,
      children: (
        <Spin spinning={true}>
          <Row style={{ height: '206px' }} />
        </Spin>
      ),
    });
    setItems(itemsTmp);
    await generateAi({ fullName: fullNameRef.current })
      .unwrap()
      .then((payload) => {
        signAiRef.current = payload.urls;
        itemsTmp[1].children = (
          <Spin spinning={spinLoad}>
            <Carousel
              afterChange={handleChangeImage}
              style={{ margin: '0 24%' }}
              dotPosition="bottom"
            >
              {payload &&
                payload.urls &&
                payload.urls?.map((load, index) => {
                  return (
                    <img
                      id={`Image${index}`}
                      className={styles.signImage}
                      src={load.url}
                      alt={`Sign ${index}`}
                    />
                  );
                })}
            </Carousel>{' '}
          </Spin>
        );
        setItems(itemsTmp);
        setSignDisable(false);
        setSpinLoad(false);
      });
  };

  const [items, setItems] = useState<TabsProps['items']>([
    {
      key: '1',
      label: `Draw`,
      children: (
        <SignatureCanvas
          ref={padRef}
          penColor="rgb(34, 30, 252)"
          canvasProps={{ width: 350, height: 200, className: 'sigCanvas' }}
          onBegin={handleBegin}
        />
      ),
    },
  ]);

  useEffect(() => {
    if (contract) {
      if (contract.fullname) {
        setFullName(contract.fullname);
        fullNameRef.current = contract.fullname;
      }
      if (contract.email) {
        setEmail(contract.email);
        emailRef.current = contract.email;
      }
    }
  }, [contract]);
  useEffect(() => {
    if (signModal) {
      if (contract.email && contract.fullname) {
        dispatch(setTimelineItemsModal(nextStageModal(StagesModal.MODAL_SIGN)));
      } else {
        dispatch(setTimelineItemsModal(nextStageModal(StagesModal.MODAL_DATA)));
      }
    }
  }, [signModal]);
  useEffect(() => {
    if (signModal) {
      if (fullNameRef.current && emailRef.current) {
        dispatch(setTimelineItemsModal(nextStageModal(StagesModal.MODAL_SIGN)));
      } else {
        dispatch(setTimelineItemsModal(nextStageModal(StagesModal.MODAL_DATA)));
      }
    }
  }, [fullNameRef.current, emailRef.current]);

  const handleOk = async () => {
    if (fullNameRef.current && emailRef.current) {
      setSignLoad(true);
      let canvas: any;

      if (currentTab === '1') {
        canvas = padRef?.current?.toDataURL();
      } else {
        canvas = await handleGetBase64(signAiRef.current[aiSignChoose].url);
      }
      await saveSign({
        shareLink: contract.shareLink ? contract.shareLink : undefined,
        controlLink: contract.controlLink ? contract.controlLink : undefined,
        fullName: fullNameRef.current,
        email: emailRef.current,
        owner: contract.controlLink ? true : false,
        base64: canvas,
        ipInfo: ipInfo,
        fromShareLink: true,
      })
        .unwrap()
        .then(async (payload) => {
          dispatch(setIsDone(true));
          dispatch(setContractSign(payload));
          dispatch(setSign(canvas));
          setSignLoad(false);
          handleCancel();
          dispatch(setResultModal({ open: true, action: ContractAction.SIGN }));
        });
    }
  };

  const handleCancel = () => {
    if (!contract.fullname && !contract.email) {
      emailRef.current = '';
      fullNameRef.current = '';
      setFullName('');
      setEmail('');
    }
    handleClear();
    const itemsTmp: TabsProps['items'] = [];
    itemsTmp.push({
      key: '1',
      label: `Draw`,
      children: (
        <SignatureCanvas
          ref={padRef}
          penColor="rgb(34, 30, 252)"
          canvasProps={{ width: 350, height: 200, className: 'sigCanvas' }}
          onBegin={handleBegin}
        />
      ),
    });
    setCurrentTab('1');
    dispatch(setSignModal(false));
  };

  const handleClear = () => {
    padRef?.current?.clear();
    setSignDisable(true);
  };

  const handleChange = (e: any) => {
    switch (e.target.id) {
      case 'FullName':
        setFullName(e.target.value);
        fullNameRef.current = e.target.value;
        break;

      case 'Email':
        setEmail(e.target.value);
        emailRef.current = e.target.value;
        break;
    }
  };
  const handleChangeTab = (e: any) => {
    setCurrentTab(e);
    setClearBtnVisible(e === '1' ? true : false);
  };
  const handleChangeImage = async (e: any) => {
    setAiSignChoose(e);
    debugger;
    // console.log(signAiRef.current);
  };
  const handleGetBase64 = async (url: string) => {
    return new Promise(async (resolve, reject) => {
      await getSignAiBase64({
        url: url,
      })
        .unwrap()
        .then(async (payload) => {
          resolve(payload);
        });
    });
  };

  return (
    <Modal
      title="Sign your document"
      open={signModal}
      centered
      onOk={handleOk}
      onCancel={handleCancel}
      footer={
        <>
          {clearBtnVisible && (
            <Button
              key="clear"
              onClick={handleClear}
              style={{ float: 'left', marginLeft: '28px' }}
            >
              Start over
            </Button>
          )}
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            disabled={signDisable}
            onClick={handleOk}
            loading={signLoad}
          >
            Sign
          </Button>
        </>
      }
    >
      <Space
        direction="vertical"
        size="large"
        style={{ display: 'flex', margin: '32px 0 0 0' }}
      >
        <Timeline
          items={[
            {
              color: timelineItems[0].color,
              children: (
                <>
                  <Card
                    style={{ opacity: timelineItems[0].opacity }}
                    bordered={true}
                  >
                    <Space
                      direction="vertical"
                      size={16}
                      style={{ display: 'flex' }}
                    >
                      <Space direction="vertical" size={2}>
                        <Title level={5} style={{ margin: '0 0 0 0' }}>
                          Leave your name and email
                        </Title>
                        <Text type="secondary">
                          We will send the signed document to this email.
                        </Text>
                      </Space>
                      <Input
                        id="FullName"
                        placeholder="Enter your full name"
                        value={fullName}
                        onChange={handleChange}
                      />
                      <Input
                        id="Email"
                        placeholder="Enter your email"
                        value={email}
                        onChange={handleChange}
                      />
                    </Space>
                  </Card>
                </>
              ),
            },
            {
              color: timelineItems[1].color,
              children: (
                <Spin
                  spinning={timelineItems[1].disable}
                  style={{ display: 'none' }}
                >
                  <Space direction="vertical" style={{ display: 'flex' }}>
                    <Card
                      style={{ opacity: timelineItems[1].opacity }}
                      bordered={true}
                    >
                      <Space
                        direction="vertical"
                        size={16}
                        style={{ display: 'flex' }}
                      >
                        <Space direction="vertical" size={2}>
                          <Title level={5} style={{ margin: '0 0 0 0' }}>
                            Leave your signature
                          </Title>
                        </Space>
                        <Tabs
                          defaultActiveKey="1"
                          items={items}
                          size="small"
                          onChange={handleChangeTab}
                        />
                      </Space>
                    </Card>
                    <Text type="secondary" style={{ fontSize: 12 }}>
                      By clicking Sign, I acknowledge that this electronic
                      signature will represent my signature in all document
                      uses, including legally binding contracts.
                    </Text>
                  </Space>
                </Spin>
              ),
            },
          ]}
        />
      </Space>
    </Modal>
  );
};
