import { ContractApprove } from 'config/types';
import { appApi } from './app-api-slice';

export const contractApproveApi = appApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      createContractApprove: builder.mutation<
        ContractApprove,
        Partial<ContractApprove>
      >({
        query: (body) => ({
          url: '/contract_approve',
          method: 'POST',
          credential: 'include',
          body,
        }),
        invalidatesTags: [
          { type: 'ContractApprove', id: 'LIST' },
          { type: 'ContractEvent', id: 'LIST' },
        ],
      }),
      getContractApprovesByShareLink: builder.query<ContractApprove[], string>({
        query: (shareLink) => ({
          url: `/contract_approve?shareLink=${shareLink}`,
          credential: 'include',
          method: 'GET',
        }),
        providesTags: [{ type: 'ContractApprove', id: 'LIST' }],
      }),
      getContractApprovesByControlLink: builder.query<
        ContractApprove[],
        Partial<ContractApprove>
      >({
        query: ({ controlLink, userId }) => ({
          url: `/contract_approve?controlLink=${controlLink}`,
          credential: 'include',
          method: 'GET',
        }),
        providesTags: [{ type: 'ContractApprove', id: 'LIST' }],
      }),
    };
  },
});

export const {
  useCreateContractApproveMutation,
  useGetContractApprovesByControlLinkQuery,
  useGetContractApprovesByShareLinkQuery,
} = contractApproveApi;
