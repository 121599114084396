import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { payloadModalSelector, setPayloadModal } from 'slices/app-slice';
import { Space, Typography, Modal, Button, Tag } from 'antd';
import TextArea from 'antd/es/input/TextArea';

export const WebhookPayloadModal = () => {
  const dispatch = useDispatch();
  const { Text } = Typography;
  const payloadModal = useSelector(payloadModalSelector);
  const handleCancel = () => {
    dispatch(setPayloadModal(false));
  };

  return (
    <Modal
      title="Payload"
      open={payloadModal}
      onOk={handleCancel}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Close
        </Button>,
      ]}
    >
      <Space direction="vertical" style={{ display: 'flex', marginTop: 24 }}>
        <Text type="secondary">Event type</Text>
        <Tag>contract.created</Tag>
        <Text type="secondary">Date</Text>
        <Tag style={{ marginBottom: 16 }}>11/02/2024</Tag>
        <TextArea
          rows={10}
          readOnly
          defaultValue='
    "created_at": "2024-01-12T11:56:34.167Z",
    "data": {
        "created_at": "2024-01-12T11:56:32.433Z",
        "email_id": "538d1172-1b79-46f3-8d96-64cf52a442e6",
        "from": "Document via Sendforsign <doc@contract.sendforsign.com>",
        "headers": [{
            "name": "X-Entity-Ref-ID",
            "value": "efV9g2EL_GwrhBnvgJAjHou5Ln5Xe4NRq"
        }],
        "subject": "Document signed",
        "tags": {
            "category": "confirm_email"
        },
        "to": ["pasha@kaleido.so"]
    },
    "type": "email.delivered"
'
        ></TextArea>
      </Space>
    </Modal>
  );
};
