import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ContractPage } from 'pages/contract-page/contract-page';
import { SharePage } from 'pages/share-page/share-page';
import { RedirectToSignIn, useAuth } from '@clerk/clerk-react';
import { ExternalPortal } from 'pages/external-portal-page/external-portal-page';
import { NotFoundBlock } from 'components/not-found-block/not-found-block';
import { AuthPages } from 'pages/auth-pages/auth-pages';

export const SwitchPage = () => {
  const { userId } = useAuth();

  return (
    <Switch>
      <Route path="/external-portal">
        <ExternalPortal />
      </Route>
      <Route path="/sharing">
        <SharePage />
      </Route>
      <Route path="/new">
        <ContractPage />
      </Route>
      <Route path="/not-found">
        <NotFoundBlock />
      </Route>
      <Route path="/onboarding">
        {userId ? <AuthPages /> : <RedirectToSignIn />}
      </Route>
      <Route path="/portal">
        {userId ? <AuthPages /> : <RedirectToSignIn />}
      </Route>
      <Route path="/components">
        {userId ? <AuthPages /> : <RedirectToSignIn />}
      </Route>
      <Route path="/settings">
        {userId ? <AuthPages /> : <RedirectToSignIn />}
      </Route>
      <Route path="/api">{userId ? <AuthPages /> : <RedirectToSignIn />}</Route>
      <Route path="/webhooks">
        {userId ? <AuthPages /> : <RedirectToSignIn />}
      </Route>
      <Route path="/webhook">
        {userId ? <AuthPages /> : <RedirectToSignIn />}
      </Route>
      <Route path="/templates">
        {userId ? <AuthPages /> : <RedirectToSignIn />}
      </Route>
      <Route path="/overview">
        {userId ? <AuthPages /> : <RedirectToSignIn />}
      </Route>
      <Route path="/documents">
        {userId ? <AuthPages /> : <RedirectToSignIn />}
      </Route>
      <Route path="/user-control">
        {userId ? <AuthPages /> : <RedirectToSignIn />}
      </Route>
      <Route path="/">
        <Redirect to="/overview" />
      </Route>
    </Switch>
  );
};
