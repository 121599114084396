import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { pdfFileLoadSelector } from 'slices/app-slice';
import styles from './pdf-viewer.module.css';
import { Document, Page, pdfjs } from 'react-pdf';
import { useResizeDetector } from 'react-resize-detector';
import { Spin } from 'antd';
import useSaveArrayBuffer from 'hooks/use-save-array-buffer';
import { PagePlaceholder } from 'config/types';
import { PlaceholderView, SpecialType } from 'config/enums';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignature } from '@fortawesome/free-solid-svg-icons';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
type Props = {
  pagePlaceholders?: PagePlaceholder[];
  onLoad?: (data: any) => void;
  forPrint?: boolean;
};
export const PdfViewer = ({
  pagePlaceholders,
  onLoad,
  forPrint = false,
}: Props) => {
  const pdfFileLoad = useSelector(pdfFileLoadSelector);
  const [pdfData, setPdfData] = useState<ArrayBuffer>();
  const [numPages, setNumPages] = useState(1);
  const [scale, setScale] = useState(1);

  const { getArrayBuffer } = useSaveArrayBuffer();

  const { width, ref } = useResizeDetector();

  useEffect(() => {
    const getValue = async () => {
      const arrayBuffer: ArrayBuffer = (await getArrayBuffer(
        'pdfFile'
      )) as ArrayBuffer;
      return setPdfData(arrayBuffer);
    };
    getValue();
  }, [pdfFileLoad]);
  console.log('pagePlaceholders', pagePlaceholders);
  return (
    <div ref={ref}>
      <Document
        loading={<Spin spinning={true} />}
        file={pdfData}
        onLoadSuccess={({ numPages }) => {
          setNumPages(numPages);
        }}
        onSourceError={() => {
          console.log('PdfViewer onSourceError');
        }}
        onLoadError={() => {
          console.log('PdfViewer onLoadError');
        }}
        onError={() => {
          console.log('PdfViewer error');
        }}
      >
        {new Array(numPages).fill(0).map((_, i) => {
          return (
            <Page
              renderTextLayer={false}
              width={1000}
              // height={1.4 * width}
              pageNumber={i + 1}
              // scale={scale}
              onLoadSuccess={(data) => {
                if (onLoad) {
                  onLoad({ pageDetails: data, docRef: ref });
                }
              }}
            >
              <div
                id={`page_${i}`}
                style={{
                  position: 'absolute',
                  margin: 0,
                  padding: 0,
                  zIndex: 1,
                  top: 0,
                  left: 0,
                  height: '100%',
                  width: '100%',
                }}
              >
                {pagePlaceholders &&
                  pagePlaceholders.length > 0 &&
                  pagePlaceholders
                    .filter(
                      (pagePlaceholder) =>
                        pagePlaceholder.pageId.toString() === i.toString()
                    )
                    .map((pagePlaceholder) => {
                      console.log('pageId', i, pagePlaceholder.pageId);
                      return (
                        <div
                          id={`insertion_${pagePlaceholder?.placeholderKey?.replaceAll(
                            '-',
                            '_'
                          )}-${pagePlaceholder.pageId}-${pagePlaceholder.id}`}
                          style={{
                            width: `${pagePlaceholder.width - 2}px`,
                            height: `${pagePlaceholder.height - 1}px`,
                            transform: `translate3d(${
                              pagePlaceholder.positionX - 2
                            }px, ${pagePlaceholder.positionY - 1}px, 0)`,
                            WebkitTransform: `translate3d(${
                              pagePlaceholder.positionX - 2
                            }px, ${pagePlaceholder.positionY - 1}px, 0)`,
                          }}
                          className={
                            forPrint
                              ? styles.placeholderPrint
                              : pagePlaceholder.owner
                              ? styles.placeholderOwner
                              : styles.placeholderOther
                          }
                        >
                          {pagePlaceholder.view?.toString() ===
                            PlaceholderView.SIGNATURE.toString() ||
                          (pagePlaceholder.isSpecial &&
                            pagePlaceholder.specialType.toString() ===
                              SpecialType.SIGN.toString()) ? (
                            <>
                              {pagePlaceholder.base64 ? (
                                <img
                                  alt="signature"
                                  src={pagePlaceholder.base64}
                                  width={pagePlaceholder.width}
                                  height={pagePlaceholder.height}
                                  style={{ objectFit: 'contain' }}
                                />
                              ) : (
                                <FontAwesomeIcon icon={faSignature} />
                              )}
                            </>
                          ) : (
                            <div
                              style={{
                                fontFamily: 'Inter',
                                fontSize: 15,
                                fontWeight: 500,
                                color: 'black',
                              }}
                            >
                              {pagePlaceholder.value
                                ? pagePlaceholder.value
                                : pagePlaceholder.name}
                            </div>
                          )}
                        </div>
                      );
                    })}
              </div>
            </Page>
          );
        })}
      </Document>
    </div>
  );
};
