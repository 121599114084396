import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  chooseWebhookSelector,
  currentClientSelector,
  setChooseWebhook,
  setWebhookModal,
  webhookModalSelector,
} from 'slices/app-slice';
import { Space, Card, Typography, Modal, Button, Input, Checkbox } from 'antd';
import { Webhook } from 'config/types';
import {
  useCreateWebhookMutation,
  useDeleteWebhookMutation,
  useUpdateWebhookMutation,
} from 'slices/webhook-api-slice';
import { useHistory } from 'react-router-dom';

export const WebhookCreateModal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { Text } = Typography;
  const [spinLoad, setSpinLoad] = useState(false);
  const [spinDeleteLoad, setSpinDeleteLoad] = useState(false);
  const [webhook, setWebhook] = useState<Webhook>({});
  const [changed, setChanged] = useState(false);
  const [fillUrl, setFillUrl] = useState(false);
  const webhookModal = useSelector(webhookModalSelector);
  const currentClient = useSelector(currentClientSelector);
  const chooseWebhook = useSelector(chooseWebhookSelector);

  const [createWebhook] = useCreateWebhookMutation();
  const [updateWebhook] = useUpdateWebhookMutation();
  const [deleteWebhook] = useDeleteWebhookMutation();
  useEffect(() => {
    if (webhookModal) {
      if (chooseWebhook) {
        setWebhook({
          url: chooseWebhook.url,
          approved: chooseWebhook.approved,
          created: chooseWebhook.created,
          seen: chooseWebhook.seen,
          sent: chooseWebhook.sent,
          signed: chooseWebhook.signed,
          fullySigned: chooseWebhook.fullySigned,
        });
        setFillUrl(true);
      } else {
        setWebhook({});
      }
    }
  }, [webhookModal]);
  const handleOk = async () => {
    setSpinLoad(true);
    if (!chooseWebhook || (chooseWebhook && !chooseWebhook.webhookKey)) {
      await createWebhook({
        url: webhook.url,
        approved: webhook.approved,
        signed: webhook.signed,
        seen: webhook.seen,
        sent: webhook.sent,
        created: webhook.created,
        fullySigned: webhook.fullySigned,
        clientKey: currentClient.clientKey,
      })
        .unwrap()
        .then(() => {
          setSpinLoad(false);
          handleCancel();
        });
    } else {
      await updateWebhook({
        id: chooseWebhook.id,
        url: webhook.url,
        approved: webhook.approved,
        signed: webhook.signed,
        seen: webhook.seen,
        sent: webhook.sent,
        created: webhook.created,
        fullySigned: webhook.fullySigned,
        clientKey: currentClient.clientKey,
      })
        .unwrap()
        .then(() => {
          setSpinLoad(false);
          handleCancel();
        });
    }
  };
  const handleCancel = () => {
    setWebhook(undefined);
    dispatch(setWebhookModal(false));
  };
  const handleDelete = async () => {
    setSpinDeleteLoad(true);
    await deleteWebhook({
      ...chooseWebhook,
      clientKey: currentClient.clientKey,
    }).then(() => {
      setSpinDeleteLoad(false);
      handleCancel();
      dispatch(setChooseWebhook(undefined));
      history.push('/webhooks');
    });
  };
  const handleChange = (e: any) => {
    switch (e.target.id) {
      case 'Url':
        setWebhook({ ...webhook, url: e.target.value });
        if (e.target.value) {
          setFillUrl(true);
        } else {
          setFillUrl(false);
        }
        break;
      case 'Created':
        setWebhook({ ...webhook, created: e.target.checked });
        break;
      case 'Sent':
        setWebhook({ ...webhook, sent: e.target.checked });
        break;
      case 'Seen':
        setWebhook({ ...webhook, seen: e.target.checked });
        break;
      case 'Signed':
        setWebhook({ ...webhook, signed: e.target.checked });
        break;
      case 'Approved':
        setWebhook({ ...webhook, approved: e.target.checked });
        break;
      case 'FullySigned':
        setWebhook({ ...webhook, fullySigned: e.target.checked });
        break;
    }
    setChanged(true);
  };

  return (
    <Modal
      title={
        chooseWebhook && chooseWebhook.webhookKey
          ? 'Edit webhook'
          : 'Add webhook'
      }
      open={webhookModal}
      centered
      onOk={handleOk}
      onCancel={handleCancel}
      footer={
        <>
          {chooseWebhook && (
            <Button
              key="delete"
              danger
              loading={spinDeleteLoad}
              onClick={handleDelete}
            >
              Delete webhook
            </Button>
          )}
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            disabled={changed && fillUrl ? false : true}
            onClick={handleOk}
            loading={spinLoad}
          >
            Save
          </Button>
        </>
      }
    >
      <Space
        direction="vertical"
        size="large"
        style={{ display: 'flex', margin: '32px 0 0 0' }}
      >
        <Card bordered={true}>
          <Space direction="vertical" size={16} style={{ display: 'flex' }}>
            <Text type="secondary">Endpoint URL</Text>
            <Input
              id="Url"
              style={{ marginBottom: 24 }}
              placeholder="https://"
              value={webhook && webhook.url ? webhook.url : ''}
              onChange={handleChange}
            />
            <Text type="secondary">Select events to listen</Text>
            <Checkbox
              id="Created"
              checked={webhook && webhook.created ? webhook.created : false}
              onChange={handleChange}
            >
              contract.created
            </Checkbox>
            <Checkbox
              id="Sent"
              checked={webhook && webhook.sent ? webhook.sent : false}
              onChange={handleChange}
            >
              contract.sent
            </Checkbox>
            <Checkbox
              id="Seen"
              checked={webhook && webhook.seen ? webhook.seen : false}
              onChange={handleChange}
            >
              contract.seen
            </Checkbox>
            <Checkbox
              id="Approved"
              checked={webhook && webhook.approved ? webhook.approved : false}
              onChange={handleChange}
            >
              contract.approved
            </Checkbox>
            <Checkbox
              id="Signed"
              checked={webhook && webhook.signed ? webhook.signed : false}
              onChange={handleChange}
            >
              contract.signed
            </Checkbox>
            <Checkbox
              id="FullySigned"
              checked={
                webhook && webhook.fullySigned ? webhook.fullySigned : false
              }
              onChange={handleChange}
            >
              contract.fullySigned
            </Checkbox>
          </Space>
        </Card>
      </Space>
    </Modal>
  );
};
