import { ContractEvent } from 'config/types';
import { appApi } from './app-api-slice';

export const contractEventApi = appApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      createContractEvent: builder.mutation<
        ContractEvent,
        Partial<ContractEvent>
      >({
        query: (body) => ({
          url: '/contract_event',
          method: 'POST',
          credential: 'include',
          body,
        }),
      }),
      getContractEvents: builder.query<ContractEvent[], Partial<ContractEvent>>(
        {
          query: ({ controlLink }) => ({
            url: `/contract_event?controlLink=${controlLink}`,
            credential: 'include',
            method: 'GET',
          }),
          providesTags: [{ type: 'ContractEvent', id: 'LIST' }],
        }
      ),
      getContractEventsByShareLink: builder.query<
        ContractEvent[],
        Partial<ContractEvent>
      >({
        query: ({ shareLink }) => ({
          url: `/contract_event?shareLink=${shareLink}`,
          credential: 'include',
          method: 'GET',
        }),
        providesTags: [{ type: 'ContractEvent', id: 'LIST' }],
      }),
    };
  },
});

export const {
  useCreateContractEventMutation,
  useGetContractEventsQuery,
  useGetContractEventsByShareLinkQuery,
} = contractEventApi;
