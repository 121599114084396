/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from 'react';
import { CopyBlock, monoBlue } from 'react-code-blocks';
import {
  Typography,
  Button,
  Row,
  Col,
  Table,
  Tag,
  Space,
  Drawer,
  Tooltip,
  Card,
  Empty,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

import type { ColumnsType } from 'antd/es/table';
import { useGetWebhooksQuery } from 'slices/webhook-api-slice';
import {
  apiKeySelector,
  currentClientSelector,
  setChooseWebhook,
  setWebhookModal,
} from 'slices/app-slice';
import { useDispatch, useSelector } from 'react-redux';
import { WebhookCreateModal } from 'components/webhook-create-modal/webhook-create-modal';
const { Title } = Typography;

interface DataType {
  key?: React.Key;
  name?: string;
  status?: string[];
  created?: string;
}

export const WebhooksPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [data, setData] = useState<DataType[]>([]);
  const [spinLoad, setSpinLoad] = useState(true);
  const currentClient = useSelector(currentClientSelector);
  const apiKey = useSelector(apiKeySelector);
  const { data: webhooksData } = useGetWebhooksQuery(null, {
    skip: !apiKey,
  });
  const [open, setOpen] = useState(false);

  const columns: ColumnsType<DataType> = [
    {
      title: 'Endpoint',
      dataIndex: 'name',
      render: (_, { key, name }) => (
        <Button
          type="link"
          onClick={() => {
            dispatch(
              setChooseWebhook(
                webhooksData.find(
                  (webhookData) => webhookData.webhookKey === key
                )
              )
            );
            history.push(`/webhook/${key}`);
          }}
        >
          {name}
        </Button>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (_, { status }) => (
        <>
          {status.map((tag) => {
            let color = 'purple';
            if (tag === 'Signed') {
              color = 'green';
            }
            return (
              <Tag color={color} key={tag}>
                {tag}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: 'Created at',
      dataIndex: 'created',
    },
  ];
  useEffect(() => {
    if (
      currentClient &&
      currentClient.clientKey &&
      webhooksData &&
      webhooksData.length > 0
    ) {
      dispatch(setChooseWebhook(undefined));
      let arrayTmp: DataType[] = [];
      webhooksData
        ?.filter(
          (webhookData) => webhookData.clientKey === currentClient.clientKey
        )
        ?.forEach((webhookData) => {
          arrayTmp.push({
            key: webhookData.webhookKey,
            name: webhookData.url,
            status: ['Enabled'],
            created: webhookData.createTime.toString(),
          });
        });
      setData(arrayTmp);
      setSpinLoad(false);
    } else if (
      currentClient &&
      currentClient.clientKey &&
      webhooksData &&
      webhooksData.length === 0
    ) {
      setSpinLoad(false);
    }
  }, [currentClient, webhooksData]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const toggleButton = (
    <Button
      type="primary"
      onClick={() => {
        dispatch(setChooseWebhook(undefined));
        dispatch(setWebhookModal(true));
      }}
    >
      Add webhook
    </Button>
  );

  return (
    <>
      <Row style={{ margin: '0 0 16px 0' }}>
        <Col>
          <Title
            level={3}
            style={{
              margin: '0',
              display: 'flex',
              textAlign: 'center',
            }}
          >
            Webhooks
          </Title>
        </Col>
        <Col flex={'auto'} />
        <Col>
          <Space>
            <Tooltip title="Open API Reference">
              <Button
                icon={<FontAwesomeIcon icon={faCode} />}
                onClick={showDrawer}
              >
                API
              </Button>
            </Tooltip>
            <Drawer
              title="API Reference"
              onClose={onClose}
              open={open}
              width={450}
            >
              <Space direction="vertical" style={{ display: 'flex' }}>
                <Title level={5} style={{ marginTop: 0 }}>
                  Add Webhook
                </Title>
                <Card
                  style={{
                    height: 400,
                    overflow: 'auto',
                    background: '#eaeef3',
                  }}
                >
                  <CopyBlock
                    language="javascript"
                    text={`curl -X POST 'https://api.sendforsign.com/api/webhook' \\
     -H 'Authorization: Bearer 123456789' \\
     -H 'Content-Type: application/json' \\
     -d '{
        "data": {
          "action": "create",
          "webhooks": [
            {
                "url": "URL",
                "created": true,
                "seen": true,
                "sent": false,
                "approved": false,
                "signed": false
            }
        ]
    }
}'
`}
                    codeBlock={false}
                    theme={monoBlue}
                    showLineNumbers={false}
                  />
                </Card>
                <Title level={5}>Update Webhook</Title>
                <Card
                  style={{
                    height: 400,
                    overflow: 'auto',
                    background: '#eaeef3',
                  }}
                >
                  <CopyBlock
                    language="javascript"
                    text={`curl -X POST 'https://api.sendforsign.com/api/webhook' \\
     -H 'Authorization: Bearer 123456789' \\
     -H 'Content-Type: application/json' \\
     -d '{
      "data": {
        "action": "update",
        "webhooks": [
            {
                "webhookKey": "WEBHOOK_KEY",
                "url": "URL",
                "created": true,
                "seen": true
            }
        ]
    }
}'
`}
                    codeBlock={false}
                    theme={monoBlue}
                    showLineNumbers={false}
                  />
                </Card>

                <Title level={5}>List of Webhooks</Title>
                <Card
                  style={{
                    height: 250,
                    overflow: 'auto',
                    background: '#eaeef3',
                  }}
                >
                  <CopyBlock
                    language="javascript"
                    text={`curl -X POST 'https://api.sendforsign.com/api/webhook' \\ 
     -H 'Authorization: Bearer 123456789' \\
     -H 'Content-Type: application/json' \\
     -d '{ 
          "data": {
              "action": "list"
              }
     }'
`}
                    codeBlock={false}
                    theme={monoBlue}
                    showLineNumbers={false}
                  />
                </Card>
              </Space>
            </Drawer>
            <Button
              type="primary"
              icon={<FontAwesomeIcon icon={faPlus} />}
              onClick={() => {
                dispatch(setWebhookModal(true));
              }}
            >
              Add webhook
            </Button>
          </Space>
        </Col>
      </Row>
      <Table
        loading={spinLoad}
        columns={columns}
        dataSource={data}
        bordered
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="You haven't added any webhooks yet"
            >
              {toggleButton}
            </Empty>
          ),
        }}
      />
      <WebhookCreateModal />
    </>
  );
};
