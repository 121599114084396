import { Contract, ContractLinkCheck } from 'config/types';
import { appApi } from './app-api-slice';

export const contractApi = appApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      createContract: builder.mutation<Contract, Partial<Contract>>({
        query: (body) => ({
          url: '/contract',
          method: 'POST',
          credential: 'include',
          body,
        }),
        invalidatesTags: [{ type: 'Contract', id: 'LIST' }],
      }),
      getContract: builder.query<Contract, string>({
        query: (controlLink) => ({
          url: `/contract?controlLink=${controlLink}`,
          credential: 'include',
        }),
      }),
      updateContract: builder.mutation<
        void,
        Pick<Contract, 'controlLink'> & Partial<Contract>
      >({
        query: ({ controlLink, ...patch }) => ({
          url: `/contract?controlLink=${controlLink}`,
          method: 'PUT',
          credential: 'include',
          body: patch,
        }),
        async onQueryStarted(
          { controlLink, ...patch },
          { dispatch, queryFulfilled }
        ) {
          const patchResult = dispatch(
            contractApi.util.updateQueryData(
              'getContract',
              'controlLink',
              (draft: any) => {
                Object.assign(draft, patch);
              }
            )
          );
          try {
            await queryFulfilled;
          } catch {
            patchResult.undo();
          }
        },
        invalidatesTags: [{ type: 'Contract', id: 'LIST' }],
      }),
      saveContractValue: builder.mutation<Contract, Partial<Contract>>({
        query: (body) => ({
          url: '/contract_value',
          method: 'POST',
          credential: 'include',
          body,
        }),
      }),
      checkContractValue: builder.mutation<Contract, Partial<Contract>>({
        query: (body) => ({
          url: '/check_contract_value',
          method: 'POST',
          credential: 'include',
          body,
        }),
      }),
      getContractByControlLink: builder.query<Contract, Partial<Contract>>({
        query: ({ controlLink, userId }) => ({
          url: `/contract?controlLink=${controlLink}${
            userId ? `&userId=${userId}` : ''
          }`,
          method: 'GET',
          credential: 'include',
        }),
        providesTags: [{ type: 'Contract', id: 'LIST' }],
      }),
      getContractByShareLink: builder.query<Contract, string>({
        query: (shareLink) => ({
          url: `/contract?shareLink=${shareLink}`,
          method: 'GET',
          credential: 'include',
        }),
        providesTags: [{ type: 'Contract', id: 'LIST' }],
      }),
      getContractsByUserId: builder.query<Contract[], void>({
        query: () => ({
          url: `/contract`,
          method: 'GET',
          credential: 'include',
        }),
        providesTags: [{ type: 'Contracts', id: 'LIST' }],
      }),
      checkContractLink: builder.mutation<
        ContractLinkCheck,
        Partial<ContractLinkCheck>
      >({
        query: (body) => ({
          url: `/contract_link`,
          method: 'POST',
          credential: 'include',
          body: body,
        }),
      }),
    };
  },
});

export const {
  useCreateContractMutation,
  useUpdateContractMutation,
  useSaveContractValueMutation,
  useCheckContractValueMutation,
  useCheckContractLinkMutation,
  useGetContractByControlLinkQuery,
  useGetContractByShareLinkQuery,
  useGetContractsByUserIdQuery,
} = contractApi;
