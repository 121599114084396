import React from 'react';
import { ErrorPage } from 'pages/error-page/error-page';

interface ErrorBoundaryState {
  error: Error | null;
  // hasError: boolean;
}
interface ErrorBoundaryProp {
  children: any;
}

export class ErrorBoundary extends React.Component<
  // React.ComponentClass,
  ErrorBoundaryProp,
  ErrorBoundaryState
> {
  static getDerivedStateFromError(error: Error) {
    console.log('getDerivedStateFromError', error);
    return { error };
  }
  state: ErrorBoundaryState = { error: null };

  render() {
    // console.log('ErrorBoundary', this.state.error);
    if (this.state.error) {
      return <ErrorPage error={this.state.error} />;
    }
    return this.props.children;
  }
}
