import React, { useEffect } from 'react';
import { Card, Space, Typography } from 'antd';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { PdfViewer } from 'components/pdf-viewer/pdf-viewer';
import { PdfSign } from 'components/pdf-sign/pdf-sign';
import { useGetContractSignsByControlLinkQuery } from 'slices/contract-sign-api-slice';
import { useDispatch, useSelector } from 'react-redux';
import {
  contractSelector,
  pdfFileLoadSelector,
  setPdfFileLoad,
  signSelector,
} from 'slices/app-slice';
import PDFMerger from 'pdf-merger-js/browser';
import { pdf } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useSendEmailsSignPdfByControlLinkMutation } from 'slices/contract-email-api-slice';
import useSaveArrayBuffer from 'hooks/use-save-array-buffer';
import { useAuth } from '@clerk/clerk-react';

export const PdfBlock = () => {
  dayjs.extend(utc);
  const dispatch = useDispatch();
  const contract = useSelector(contractSelector);
  // const pdfFile = useSelector(pdfFileSelector);
  const pdfFileLoad = useSelector(pdfFileLoadSelector);
  const sign = useSelector(signSelector);
  const { getArrayBuffer, setArrayBuffer } = useSaveArrayBuffer();
  const { userId } = useAuth();
  const { Title } = Typography;

  const { data: contractSignsData, refetch: contractSignsRefetch } =
    useGetContractSignsByControlLinkQuery(
      { controlLink: contract.controlLink, userId: userId },
      {
        skip: contract.controlLink ? false : true,
      }
    );

  const [sendEmailPdf] = useSendEmailsSignPdfByControlLinkMutation();
  useEffect(() => {
    if (sign) {
      contractSignsRefetch();
    }
  }, [sign]);
  useEffect(() => {
    const render = async () => {
      const pdfFile = (await getArrayBuffer('pdfFileOriginal')) as ArrayBuffer;
      if (!pdfFile || pdfFile.byteLength === 0) {
        return;
      }
      const merger = new PDFMerger();
      // let arrayBuffer = await getPdf().arrayBuffer();
      let arrayBuffer = pdfFile;
      await merger.add(arrayBuffer);

      const blob = await pdf(<PdfSign signs={contractSignsData} />).toBlob();
      arrayBuffer = await blob.arrayBuffer();
      await merger.add(arrayBuffer);

      const mergedPdfBlob = await merger.saveAsBlob();
      const mergedPdf = await mergedPdfBlob.arrayBuffer();
      // dispatch(setPdfFile(mergedPdf));
      await setArrayBuffer('pdfFile', mergedPdf);
      dispatch(setPdfFileLoad(pdfFileLoad + 1));
      if (sign) {
        const formData = new FormData();
        formData.append('pdf', mergedPdfBlob);
        await sendEmailPdf({
          controlLink: contract.controlLink,
          userId: userId,
          data: formData,
        });
      }
    };
    if (contractSignsData && contractSignsData.length > 0) {
      render();
    }
  }, [contractSignsData]);

  return (
    <Card
      style={{ opacity: 1 }}
      bordered={true}
      className="SharingCardToHide"
      id="part-2"
    >
      <Space direction="vertical" size={16} style={{ display: 'flex' }}>
        <Space direction="vertical" size={2} className="SharingDocHeader">
          <Title level={4} style={{ margin: '0 0 0 0' }}>
            Review your document
          </Title>
        </Space>
        <PdfViewer />
      </Space>
    </Card>
  );
};
