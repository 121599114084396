import { ContractEmail, PdfFormData } from 'config/types';
import { appApi } from './app-api-slice';

export const contractEmailApi = appApi.injectEndpoints({
  endpoints: (builder) => {
    return {
      // sendEmails: builder.mutation<ContractPdf, Partial<ContractPdf>>({
      sendEmailsSignByShareLink: builder.mutation<
        ContractEmail,
        Partial<ContractEmail>
      >({
        query: (body) => ({
          url: `/contract_email_sign`,
          method: 'POST',
          credential: 'include',
          body: body,
        }),
      }),
      sendEmailsApproveByShareLink: builder.mutation<
        ContractEmail,
        Partial<ContractEmail>
      >({
        query: (body) => ({
          url: `/contract_email_approve`,
          method: 'POST',
          credential: 'include',
          body: body,
        }),
      }),
      sendEmailsSignByControlLink: builder.mutation<
        ContractEmail,
        Partial<ContractEmail>
      >({
        query: (body) => ({
          url: `/contract_email_sign`,
          method: 'POST',
          credential: 'include',
          body: body,
        }),
      }),
      sendEmailsSignPdfByControlLink: builder.mutation<
        PdfFormData,
        Partial<PdfFormData>
      >({
        query: ({ controlLink, userId, data }) => ({
          url: `/contract_email_sign_pdf?controlLink=${controlLink}`,
          method: 'POST',
          credential: 'include',
          body: data,
        }),
      }),
      sendEmailsSignPdfByShareLink: builder.mutation<
        PdfFormData,
        Partial<PdfFormData>
      >({
        query: ({ shareLink, data }) => ({
          url: `/contract_email_sign_pdf?shareLink=${shareLink}`,
          method: 'POST',
          credential: 'include',
          body: data,
        }),
      }),
    };
  },
});

export const {
  useSendEmailsSignByShareLinkMutation,
  useSendEmailsApproveByShareLinkMutation,
  useSendEmailsSignByControlLinkMutation,
  useSendEmailsSignPdfByControlLinkMutation,
  useSendEmailsSignPdfByShareLinkMutation,
} = contractEmailApi;
