/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';

import './external-portal.module.css';
import {
  Space,
  Typography,
  Row,
  Col,
  Upload,
  Card,
  Tooltip,
  Divider,
} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInbox } from '@fortawesome/free-solid-svg-icons';

const { Title, Text } = Typography;
const { Dragger } = Upload;

export const ExternalPortal: React.FC = () => {
  return (
    <Row>
      <Col flex={'auto'}></Col>
      <Col span="24" style={{ maxWidth: '1200px' }}>
        {/* 
          -
          Hero block starts
          -
          */}

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span="24">
            <Space
              direction="vertical"
              style={{ display: 'flex', margin: '24px 0' }}
            >
              <Title level={3} style={{ margin: '0 0 0 0' }}>
                Choose a prepared document or upload your own
              </Title>
              <Text type="secondary">
                Afterward, you can send, sign, and do more with this document
              </Text>
            </Space>
          </Col>
        </Row>

        {/* Block ends */}

        {/* 
          -
          Contract block starts
          -
          */}

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span="24">
            <Card style={{ opacity: 1 }} bordered={true}>
              <Space direction="vertical" size={16} style={{ display: 'flex' }}>
                <Space direction="vertical" size={2}>
                  <Title level={4} style={{ margin: '0 0 0 0' }}>
                    Upload PDF or Word
                  </Title>
                </Space>
                <Dragger>
                  <p className="ant-upload-drag-icon">
                    <FontAwesomeIcon icon={faInbox} size="2xl" />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Support for a single upload. Strictly prohibited from
                    uploading company data or other banned files.
                  </p>
                </Dragger>
              </Space>
            </Card>
          </Col>
        </Row>

        {/* Block ends */}

        <Divider style={{ margin: '40px 0' }} />

        {/* 
          -
          Contract block starts
          -
          */}

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span="24">
            <Card style={{ opacity: 1 }} bordered={true}>
              <Space direction="vertical" size={16} style={{ display: 'flex' }}>
                <Space direction="vertical" size={2}>
                  <Title level={4} style={{ margin: '0 0 0 0' }}>
                    Prepared documents
                  </Title>
                </Space>
                <Space wrap size={16} align="start">
                  <Card
                    title={
                      <Tooltip title="YC SAFE Valuation Cap No Discount">
                        <Text>YC SAFE Valuation Cap No Discount</Text>
                      </Tooltip>
                    }
                    style={{ width: 300, minHeight: 150 }}
                    type="inner"
                    hoverable
                  >
                    <Text>
                      Click to create YC SAFE Valuation Cap No Discount
                    </Text>
                  </Card>
                  <Card
                    title={
                      <Tooltip title="Non-Disclosure Agreement">
                        <Text>Non-Disclosure Agreement</Text>
                      </Tooltip>
                    }
                    style={{ width: 300, minHeight: 150 }}
                    type="inner"
                    hoverable
                  >
                    <Text>Click to create Non-Disclosure Agreement</Text>
                  </Card>
                  <Card
                    title={
                      <Tooltip title="Sales agreement">
                        <Text>Sales agreement</Text>
                      </Tooltip>
                    }
                    style={{ width: 300, minHeight: 150 }}
                    type="inner"
                    hoverable
                  >
                    <Text>Click to create Sales agreement</Text>
                  </Card>
                </Space>
              </Space>
            </Card>
          </Col>
        </Row>

        {/* Block ends */}
      </Col>
      <Col flex={'auto'}></Col>
    </Row>
  );
};
