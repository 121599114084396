/* eslint-disable jsx-a11y/iframe-has-title */
import React, { lazy, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';
import { Layout, Spin } from 'antd';
import { Buffer } from 'buffer';
import { useDispatch, useSelector } from 'react-redux';
import { Content } from 'antd/es/layout/layout';

import { OverviewPage } from 'pages/overview-page/overview-page';
import { DocumentPage } from 'pages/document-page/document-page';
import { TemplatePage } from 'pages/template-page/template-page';
import { ApiPage } from 'pages/api-page/api-page';
import { ComponentsPage } from 'pages/components-page/components-page';
import { SettingsPage } from 'pages/settings-page/settings-page';
import { WebhooksPage } from 'pages/webhooks-page/webhooks-page';
import { WebhookPage } from 'pages/webhook-page/webhook-page';
import { PortalPage } from 'pages/portal-page/portal-page';
import { OnboardingPage } from 'pages/onboarding-page/onboarding-page';
import { SideMenu } from 'components/dashboard/side-menu/side-menu';
import { HeaderMenu } from 'components/dashboard/header-menu/header-menu';
import useApiKey from 'hooks/use-api-key';
import { useGetUserInfoMutation } from 'slices/user-info-api-slice';
import { encodeUserInfo } from 'utils/data-helper';
import {
  apiKeySelector,
  setActivePlan,
  setCurrentApiKey,
  setCurrentSecret,
} from 'slices/app-slice';

// const Document = React.lazy(() => import('../document-page'));
// const Template = React.lazy(() => import('../template-page'));
export const AuthPages: React.FC = () => {
  const dispatch = useDispatch();
  const apiKey = useSelector(apiKeySelector);
  const { user } = useUser();
  const { setApiKey } = useApiKey();

  const [getUserInfo] = useGetUserInfoMutation();

  useEffect(() => {
    if (user) {
      const getUser = async () => {
        await getUserInfo({ payload: encodeUserInfo(user) })
          .unwrap()
          .then((payload) => {
            // console.log('payload', payload);
            let apiKeySecret = Buffer.from(payload.result, 'base64').toString();
            let apiKeyTmp = apiKeySecret.split(';')[0];
            let secretTmp = apiKeySecret.split(';')[1];
            dispatch(setCurrentSecret(secretTmp));
            setApiKey(apiKeyTmp);
            dispatch(setCurrentApiKey(apiKeyTmp));
            dispatch(
              setActivePlan({
                active: payload.activePlan,
                quantity: payload.quantity ? payload.quantity : 20,
              })
            );
          });
      };
      if (!apiKey) {
        getUser();
      }
    }
  }, [user, apiKey]);
  if (!apiKey) {
    return (
      <Layout>
        <Spin
          spinning={true}
          style={{
            width: '100wh',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            marginTop: '100px',
          }}
        />
      </Layout>
    );
  }
  return (
    <Layout>
      <SideMenu />
      <Layout style={{ backgroundColor: '#fff' }}>
        <HeaderMenu />
        <Content
          style={{ padding: '32px', background: '#fafafa', overflow: 'auto' }}
        >
          <Switch>
            <Route path="/onboarding">
              <OnboardingPage />
            </Route>
            <Route path="/portal">
              <PortalPage />
            </Route>
            <Route path="/api">
              <ApiPage />
            </Route>
            <Route path="/components">
              <ComponentsPage />
            </Route>
            <Route path="/settings">
              <SettingsPage />
            </Route>
            <Route path="/webhooks">
              <WebhooksPage />
            </Route>
            <Route path="/webhook">
              <WebhookPage />
            </Route>
            <Route path="/templates">
              <TemplatePage />
            </Route>
            <Route path="/overview">
              <OverviewPage />
            </Route>
            <Route path="/documents">
              <DocumentPage />
            </Route>
          </Switch>
        </Content>
      </Layout>
    </Layout>
  );
};
